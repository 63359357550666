import {
  getLocations,
  getLocation,
  locationCreate,
  locationUpdate,
  locationMapping,
  locationPostMapping,
  locationOpeningHours,
  locationOpeningHourCreate,
  locationOpeningHourUpdate,
  locationOpeningHourDelete,
  locationExceptionalOpeningHours,
  locationExceptionalOpeningHourCreate,
  locationExceptionalOpeningHourUpdate,
  locationExceptionalOpeningHourDelete,
  locationUrlCreate,
  locationUrlUpdate,
  locationUrlDelete,
  locationExternalIdInfo,
  locationInfoUpdate,
} from '@/services/location.service'

import { orderOpeningHoursObject } from '@/utils/date.util'
import { tableOptions } from '@/utils/table.util'
import { getShortLocal } from '@/utils/string.util'

const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION'
const SET_LOCATIONS = 'SET_LOCATIONS'
const SET_LOCATIONS_MOBILE = 'SET_LOCATIONS_MOBILE'
const SET_LOCATIONS_PAGINATION = 'SET_LOCATIONS_PAGINATION'
const SET_CURRENT_LOCATION_OPENING_HOURS = 'SET_CURRENT_LOCATION_OPENING_HOURS'
const SET_CURRENT_LOCATION_URLS = 'SET_CURRENT_LOCATION_URLS'
const SET_CURRENT_LOCATION_MAPPING = 'SET_CURRENT_LOCATION_MAPPING'
const CREATE_CURRENT_LOCATION_URLS = 'CREATE_CURRENT_LOCATION_URLS'
const UPDATE_CURRENT_LOCATION_URLS = 'UPDATE_CURRENT_LOCATION_URLS'
const DELETE_CURRENT_LOCATION_URLS = 'DELETE_CURRENT_LOCATION_URLS'
const CREATE_CURRENT_LOCATION_OPENING_HOURS = 'CREATE_CURRENT_LOCATION_OPENING_HOURS'
const UPDATE_CURRENT_LOCATION_OPENING_HOURS = 'UPDATE_CURRENT_LOCATION_OPENING_HOURS'
const DELETE_CURRENT_LOCATION_OPENING_HOURS = 'DELETE_CURRENT_LOCATION_OPENING_HOURS'
const SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS = 'SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS'
const CREATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS = 'CREATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS'
const UPDATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS = 'UPDATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS'
const DELETE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS = 'DELETE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    currentLocation: {},
    currentLocationMapping: [],
    currentLocationUrls: [],
    currentLocationOpeningHours: [],
    currentLocationExceptionalOpeningHours: [],
    locations: [],
    locationsMobile: [],
    locationsPagination: {},
  }
}

const state = getDefaultState()

const mutations = {
  [SET_LOCATIONS]: (state, locations) => {
    state.locations = locations
  },
  [SET_CURRENT_LOCATION_MAPPING]: (state, locations) => {
    state.currentLocationMapping = locations || []
  },
  [SET_LOCATIONS_MOBILE]: (state, { locations, reset }) => {
    if (reset) {
      state.locationsMobile = []
    }
    state.locationsMobile.push(...locations)
  },
  [SET_CURRENT_LOCATION]: (state, location) => {
    state.currentLocation = location
  },
  [UPDATE_CURRENT_LOCATION]: (state, updatedLocation) => {
    state.currentLocation = Object.assign(state.currentLocation, updatedLocation)
  },
  [SET_LOCATIONS_PAGINATION]: (state, pagination) => {
    state.locationsPagination = pagination
  },
  [SET_CURRENT_LOCATION_URLS]: (state, urls) => {
    state.currentLocationUrls = urls
  },
  [CREATE_CURRENT_LOCATION_URLS]: (state, url) => {
    state.currentLocationUrls.push(url)
  },
  [UPDATE_CURRENT_LOCATION_URLS]: (state, url) => {
    state.currentLocationUrls = state.currentLocationUrls.map(u => {
      if (u.id === url.id) {
        return url
      }
      return u
    })
  },
  [DELETE_CURRENT_LOCATION_URLS]: (state, url) => {
    state.currentLocationUrls = state.currentLocationUrls.filter(u => u.id !== url.id)
  },
  [SET_CURRENT_LOCATION_OPENING_HOURS]: (state, openingHours) => {
    state.currentLocationOpeningHours = openingHours.map(openingHour => ({
      ...openingHour,
      hours: orderOpeningHoursObject(openingHour.hours),
    }))
  },
  [CREATE_CURRENT_LOCATION_OPENING_HOURS]: (state, openingHour) => {
    state.currentLocationOpeningHours.push({
      ...openingHour,
      hours: orderOpeningHoursObject(openingHour.hours),
    })
  },
  [UPDATE_CURRENT_LOCATION_OPENING_HOURS]: (state, openingHours) => {
    state.currentLocationOpeningHours = state.currentLocationOpeningHours.map(o => {
      if (o.id === openingHours.id) {
        return {
          ...openingHours,
          hours: orderOpeningHoursObject(openingHours.hours),
        }
      }
      return o
    })
  },
  [DELETE_CURRENT_LOCATION_OPENING_HOURS]: (state, openingHours) => {
    state.currentLocationOpeningHours = state.currentLocationOpeningHours.filter(o => o.id !== openingHours.id)
  },
  [SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS]: (state, exceptionalOpeningHours) => {
    state.currentLocationExceptionalOpeningHours = exceptionalOpeningHours
  },
  [CREATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS]: (state, exceptionalOpeningHour) => {
    state.currentLocationExceptionalOpeningHours.push(exceptionalOpeningHour)
  },
  [UPDATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS]: (state, exceptionalOpeningHours) => {
    state.currentLocationExceptionalOpeningHours = state.currentLocationExceptionalOpeningHours.map(eo => {
      if (eo.id === exceptionalOpeningHours.id) {
        return exceptionalOpeningHours
      }
      return eo
    })
  },
  [DELETE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS]: (state, exceptionalOpeningHours) => {
    state.currentLocationExceptionalOpeningHours = state.currentLocationExceptionalOpeningHours.filter(
      eo => eo.id !== exceptionalOpeningHours.id
    )
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  getLocations: ({ commit, rootState }, { tableParams, resetLocations, search }) => {
    const urlParams = tableOptions(tableParams, search, '', true)
    return getLocations(rootState.client.currentClient.id, urlParams)
      .then(response => {
        commit(SET_LOCATIONS, response.results)
        commit(SET_LOCATIONS_MOBILE, { locations: response.results, reset: resetLocations })
        delete response.results
        commit(SET_LOCATIONS_PAGINATION, response)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getLocation: async ({ commit, rootState }, { locationId }) => {
    const FO = rootState.frontoffice.currentFrontOffice
    const clientDefaultLocaleId = rootState.client.currentClient.defaultLocaleId

    const localeId = FO?.localeId || clientDefaultLocaleId
    const localeDefault = FO?.localeId ? localeId === FO?.defaultLocaleId : true

    const location = await getLocation({
      clientId: rootState.client.currentClient.id,
      locationId,
      frontOfficeId: FO?.id || null,
      localeId,
      isDefaultLocaleId: localeDefault,
    })

    commit(SET_CURRENT_LOCATION, location)
    commit(SET_CURRENT_LOCATION_OPENING_HOURS, location.locationOpeningHours)
    commit(SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, location.locationExceptionalOpeningHours)
    commit(SET_CURRENT_LOCATION_URLS, location.locationUrls)

    return Promise.resolve()
  },
  getCurrentLocationsMapping: async ({ commit }, { id, accountGmb, account }) => {
    const { data } = await locationMapping(id, accountGmb, account)
    commit(SET_CURRENT_LOCATION_MAPPING, data)
    return Promise.resolve()
  },
  updateCurrentLocationsMapping: async ({ commit }, { id, accountGmb, account, placeId, locationsId }) => {
    const { data } = await locationPostMapping(id, accountGmb, account, placeId, locationsId)
    commit(SET_CURRENT_LOCATION_MAPPING, data)
    return Promise.resolve()
  },
  updateLocation: async ({ commit, state, rootState, dispatch }, data) => {
    const userLocal = getShortLocal(rootState.backoffice.currentUser.locale)
    const hasFrontOffice = !!rootState.frontoffice?.currentFrontOffice?.localeId
    const localeId = hasFrontOffice
      ? rootState.frontoffice.currentFrontOffice.localeId
      : rootState.client.currentClient.defaultLocaleId
    const isDefaultLocaleId = hasFrontOffice
      ? localeId === rootState.frontoffice.currentFrontOffice.defaultLocaleId
      : true
    const { data: newCurrentLocation } = await locationUpdate(
      {
        clientId: rootState.client.currentClient.id,
        id: state.currentLocation.id,
        frontOfficeId: hasFrontOffice ? rootState.frontoffice.currentFrontOffice.id : null,
        localeId,
        isDefaultLocaleId,
      },
      data
    )
    commit(UPDATE_CURRENT_LOCATION, newCurrentLocation)
    if (data.countryCodeChanged) {
      return dispatch(
        'tool/getSubDivisions',
        { locale: userLocal, countryCode: state.currentLocation.countryCode },
        { root: true }
      )
    }
  },
  updateLocationInfo: async ({ rootState }, data) => {
    const response = await locationInfoUpdate(rootState.client.currentClient.id, data)
    return response
  },

  updateUrl: ({ state, commit, rootState }, { url, status }) => {
    if (status === 'create') {
      return locationUrlCreate(rootState.client.currentClient.id, state.currentLocation.id, url).then(response => {
        commit(CREATE_CURRENT_LOCATION_URLS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'update') {
      return locationUrlUpdate(rootState.client.currentClient.id, state.currentLocation.id, url.id, url).then(
        response => {
          commit(UPDATE_CURRENT_LOCATION_URLS, response.data)
          return Promise.resolve()
        }
      )
    } else if (status === 'delete') {
      return locationUrlDelete(rootState.client.currentClient.id, state.currentLocation.id, url.id).then(() => {
        commit(DELETE_CURRENT_LOCATION_URLS, url)
        return Promise.resolve()
      })
    }
  },
  updateOpeningHour: ({ state, commit, rootState }, { openingHour, status, period }) => {
    if (status === 'create') {
      return locationOpeningHourCreate(
        rootState.client.currentClient.id,
        state.currentLocation.id,
        openingHour,
        period
      ).then(response => {
        commit(CREATE_CURRENT_LOCATION_OPENING_HOURS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'update') {
      return locationOpeningHourUpdate(
        rootState.client.currentClient.id,
        state.currentLocation.id,
        openingHour.id,
        openingHour
      ).then(response => {
        commit(UPDATE_CURRENT_LOCATION_OPENING_HOURS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'delete') {
      return locationOpeningHourDelete(
        rootState.client.currentClient.id,
        state.currentLocation.id,
        openingHour.id
      ).then(() => {
        commit(DELETE_CURRENT_LOCATION_OPENING_HOURS, openingHour)
        return Promise.resolve()
      })
    }
  },
  updateExceptionalOpeningHour: ({ state, commit, rootState }, { exceptionalOpeningHour, status }) => {
    const FO = rootState.frontoffice.currentFrontOffice
    const client = rootState.client.currentClient
    const clientDefaultLocaleId = client.defaultLocaleId
    const clientId = client.id
    const locationId = state.currentLocation.id

    const localeId = FO?.localeId || clientDefaultLocaleId
    const localeDefault = FO?.localeId ? localeId === FO?.defaultLocaleId : true

    if (status === 'create') {
      return locationExceptionalOpeningHourCreate({
        clientId,
        locationId,
        exceptionalOpeningHour,
        localeId,
        localeDefault,
      }).then(response => {
        commit(CREATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'update') {
      return locationExceptionalOpeningHourUpdate({
        clientId,
        locationId,
        exceptionalOpeningHour,
        localeId,
        localeDefault,
      }).then(response => {
        commit(UPDATE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, response.data)
        return Promise.resolve()
      })
    } else if (status === 'delete') {
      return locationExceptionalOpeningHourDelete({
        clientId,
        locationId,
        exceptionalOpeningHour,
        localeId,
        localeDefault,
      }).then(() => {
        commit(DELETE_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, exceptionalOpeningHour)
        return Promise.resolve()
      })
    }
  },
  getOpeningHour: async ({ commit, rootState }, { locationId }) => {
    const { data } = await locationOpeningHours(rootState.client.currentClient.id, locationId)
    commit(SET_CURRENT_LOCATION_OPENING_HOURS, data.results)
  },
  getExceptionalOpeningHour: async ({ commit, rootState }, { period, locationId }) => {
    const FO = rootState.frontoffice.currentFrontOffice
    const clientDefaultLocaleId = rootState.client.currentClient.defaultLocaleId
    const localeId = FO?.localeId || clientDefaultLocaleId
    const localeDefault = FO?.localeId ? localeId === FO?.defaultLocaleId : true

    await locationExceptionalOpeningHours({
      clientId: rootState.client.currentClient.id,
      locationId,
      period,
      localeId,
      localeDefault,
    }).then(response => {
      commit(SET_CURRENT_LOCATION_EXCEPTIONAL_OPENING_HOURS, response.data.results)
    })
  },
  createLocation: (_, data) => {
    return locationCreate(data.clientId, data).then(response => {
      return Promise.resolve(response.data)
    })
  },
  getUniqueExternalId: async ({ rootState }, externalId) => {
    const { data } = await locationExternalIdInfo(rootState.client.currentClient.id, externalId)
    return data.results.length > 0
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
