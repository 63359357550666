import { sendPostRequest, sendGetRequest } from '@/services/api.service'
import { API_TOOLS, API_GEONAMES } from '@/config/api.config'

export const uploadMedia = (clientId, data, frontOfficeId = null) => {
  if (frontOfficeId !== null) {
    return sendPostRequest(
      `${API_TOOLS.BASE_API_URL}${API_TOOLS.UPLOAD_MEDIA}?clientId=${clientId}&frontOfficeId=${frontOfficeId}`,
      data
    )
  }
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.UPLOAD_MEDIA}?clientId=${clientId}`, data)
}

export const cloneMedia = (clientId, data) => {
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.CLONE_MEDIA}?clientId=${clientId}`, data)
}

export const removeMedia = (clientId, data) => {
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.REMOVE_MEDIA}?clientId=${clientId}`, data)
}

export const geocode = address => {
  return sendGetRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.GEOCODE}?address=${address}`)
}

export const getInternationalPhone = data => {
  return sendPostRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.INTERNATIONAL_PHONE}`, data)
}

export const countries = (locale = 'en') => {
  return sendGetRequest(`${API_TOOLS.BASE_API_URL}${API_TOOLS.COUNTRIES}?locale=${locale}`)
}

export const subDivisions = (locale = 'en', countryCode) => {
  return sendGetRequest(
    `${API_GEONAMES.BASE_API_URL}${API_GEONAMES.ADMINISTRATIVE_DIVISIONS}/${API_GEONAMES.API}/${locale}/${countryCode}`,
    {
      headers: {
        Authorization: API_GEONAMES.BEARER_TOKEN,
      },
      timeout: 3000,
    }
  )
}

export const getGeonames = body => {
  return sendPostRequest(`${API_GEONAMES.BASE_API_URL}${API_GEONAMES.GEONAMES}/${API_GEONAMES.SEARCH}`, body, {
    headers: {
      Authorization: API_GEONAMES.BEARER_TOKEN,
    },
    timeout: 3000,
  })
}
