import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import store from '@/store'
import { URLS } from '@/config/urls.config'
import { getUserToken, isLogged, isAuthorized, isNavigationAccess } from '@/utils/auth.util'
import { userRoles } from '@/config/permissions.config'

Vue.use(VueRouter)

const routes = [
  // Redirect all legacy routes (before clients/:clientId exist)
  {
    path: '/network/*',
    redirect: to => {
      return {
        path: `/clients/id${to.path}`,
      }
    },
  },
  {
    path: '/',
    redirect: {
      path: '/clients/id',
    },
  },
  {
    path: '/clients/:clientId',
    name: 'ClientId',
    component: () => import('@/views/Backoffice.vue'),
    redirect: to => {
      return {
        path: '/clients/:clientId/network/locations/lpe',
        params: { clientId: to.params.clientId || 'id' },
      }
    },
    meta: {
      title: i18n.t('head.title.root'),
      requiresAuth: true,
    },
    children: [
      {
        path: ':module(network)/gmb',
        name: 'GMB',
        component: () => import('@/views/Gmb.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'qa/:name(accounts%2F[0-9]+)?',
            name: 'GmbQa',
            component: () => import('@/views/Gmb/Qa.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'gmb_qa',
            },
          },
          {
            path: 'qa/:name(accounts%2F[0-9]*%2Flocations%2F[0-9]*)/:currentTab(\\w+)?',
            name: 'GmbQaEdit',
            component: () => import('@/views/Gmb/QaEdit.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'gmb_qa',
            },
          },
          {
            path: 'local-posts/:name(accounts%2F[0-9]+)?',
            name: 'GmbLocalPostsList',
            component: () => import('@/views/Gmb/LocalPostsList.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'gmb_lp',
            },
          },
          {
            path: 'local-posts/:name(accounts%2F[0-9]*%2Flocations%2F[0-9]*)/:currentTab(\\w+)?',
            name: 'GmbLocalPosts',
            component: () => import('@/views/Gmb/LocalPosts.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'gmb_lp',
            },
          },
          {
            path: 'locations/:name(accounts%2F[0-9]+)?/:currentTab(\\w+)?',
            name: 'LocationsGmb',
            component: () => import('@/views/Gmb/Locations.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
            },
          },
          {
            path: 'locations/:name(accounts%2F[0-9]*%2Flocations%2F[0-9]*)/:currentTab(\\w+)?',
            name: 'LocationGmb',
            component: () => import('@/views/Gmb/LocationsId.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
            },
          },
          {
            path: 'accounts',
            name: 'Accounts',
            component: () => import('@/views/Gmb/Accounts.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'gmb',
            },
          },
          {
            path: 'accounts/:name(accounts%2F[0-9]*)',
            name: 'AccountsId',
            component: () => import('@/views/Gmb/AccountsId.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'gmb',
            },
          },
        ],
      },
      {
        path: ':module(network)/locations',
        component: () => import('@/views/Locations.vue'),
        children: [
          {
            path: 'lpe',
            name: 'Locations',
            component: () => import('@/views/Locations/List.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
            },
          },
          {
            path: 'manager',
            name: 'LocationsManager',
            component: () => import('@/views/Locations/Manager.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
              roles: [userRoles.superAdmin],
            },
          },
          {
            path: 'create',
            name: 'LocationCreate',
            component: () => import('@/views/Locations/Create.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
              roles: [userRoles.superAdmin, userRoles.brandAdmin, userRoles.agency],
            },
          },
          {
            path: ':id([0-9]*)/:currentTab(\\w+)?',
            name: 'Location',
            component: () => import('@/views/Locations/Id.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'locations',
            },
          },
        ],
      },
      {
        path: ':module(network)/user-express',
        component: () => import('@/views/UserExpress.vue'),
        children: [
          {
            path: 'list/:currentTab(\\w+)?',
            name: 'UserExpress',
            component: () => import('@/views/UserExpress/List.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'user_express',
            },
          },
          {
            path: 'create',
            name: 'UserExpressCreate',
            component: () => import('@/views/UserExpress/Create.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'user_express',
              roles: [userRoles.superAdmin, userRoles.brandAdmin, userRoles.agency, userRoles.groupAdmin],
            },
          },
          {
            path: ':id([0-9]*)/:currentTab(\\w+)?',
            name: 'UserExpressId',
            component: () => import('@/views/UserExpress/IdUser.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'user_express',
            },
          },
          {
            path: 'campaign/:id([0-9]*)/:currentTab(\\w+)?',
            name: 'CampaignId',
            component: () => import('@/views/UserExpress/IdCampaign.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'user_express',
            },
          },
        ],
      },
      {
        path: ':module(network)/oh-group',
        component: () => import('@/views/OhGroup.vue'),
        children: [
          {
            path: 'list',
            name: 'OhGroup',
            component: () => import('@/views/OhGroup/List.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'oh_group',
            },
          },
          {
            path: 'create',
            name: 'OhGroupCreate',
            component: () => import('@/views/OhGroup/Create.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'oh_group',
              roles: [userRoles.superAdmin, userRoles.brandAdmin, userRoles.agency, userRoles.groupAdmin],
            },
          },
        ],
      },
      {
        path: ':module(network)/presence-management',
        name: 'PresenceManagement',
        component: () => import('@/views/PresenceManagement.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':module(network)/frontoffices',
        component: () => import('@/views/FrontOffices.vue'),
        children: [
          {
            path: '',
            name: 'FrontOffices',
            component: () => import('@/views/FrontOffices/List.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'locator',
            },
          },
          {
            path: 'create',
            name: 'FrontOfficeCreate',
            component: () => import('@/views/FrontOffices/Create.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'locator',
              roles: [userRoles.superAdmin],
            },
          },
          {
            path: ':id/:currentTab(\\w+)?',
            name: 'FrontOffice',
            component: () => import('@/views/FrontOffices/Id.vue'),
            meta: {
              productLine: 'influence',
              requiresAuth: true,
              navigation: 'locator',
            },
          },
        ],
      },
      {
        path: 'password',
        name: 'Password',
        component: () => import('@/views/Password.vue'),
        meta: {
          productLine: 'admin',
          requiresAuth: true,
          navigation: 'password',
        },
      },
      {
        path: ':module(network)/radar',
        name: 'Radar',
        component: () => import('@/views/Radar.vue'),
        meta: {
          productLine: 'measure',
          requiresAuth: true,
          navigation: 'radar',
        },
      },
      {
        path: 'kpis',
        component: () => import('@/views/Kpis.vue'),
        meta: {
          productLine: 'measure',
          requiresAuth: true,
          navigation: 'kpis',
        },
        children: [
          {
            path: '',
            name: 'Kpis',
            component: () => import('@/views/Kpis/List.vue'),
            meta: {
              productLine: 'admin',
              requiresAuth: true,
              navigation: 'clients',
            },
          },
          {
            path: 'lpe_performance',
            name: 'LPEPerformance',
            component: () => import('@/views/Kpis/LPEPerformance.vue'),
            meta: {
              productLine: 'admin',
              requiresAuth: true,
              navigation: 'clients',
            },
          },
        ],
      },
      {
        path: ':module(network)/leads',
        component: () => import('@/views/Leads.vue'),
        children: [
          {
            path: 'county-intention',
            name: 'CountyIntention',
            component: () => import('@/views/Leads/CountyIntention.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'leads',
            },
          },
          {
            path: 'tabs/:currentTab(\\w+)?',
            name: 'Leads',
            component: () => import('@/views/Leads/Leads.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'leads',
            },
          },
          {
            path: ':locationId/:id([0-9]*)/:currentTab(\\w+)?',
            name: 'Lead',
            component: () => import('@/views/Leads/Id.vue'),
            meta: {
              productLine: 'data',
              requiresAuth: true,
              navigation: 'leads',
            },
          },
        ],
      },
    ],
  },
  { path: '/no-access', name: 'NoAccess', component: () => import('@/views/NoAccess.vue') },
  { path: '*', name: 'Error', component: () => import('@/views/Error.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const DEFAULT_TITLE = i18n.t('head.title.root')

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE
  const appState = store.state.backoffice

  isLogged(to, next, appState)

  if (isAuthorized(to, appState, getUserToken())) {
    return next()
  }

  if (getUserToken() !== undefined) {
    if (appState.currentUser && !isNavigationAccess(to.meta.roles, appState.currentUser)) {
      return next({ name: 'Error' })
    }
    return next()
  }

  await store.dispatch('backoffice/userLogout')
  return window.location.assign(`${URLS.SSO}/?app_version=2&redirect=${encodeURIComponent(to.fullPath)}&sign_out=1`)
})

export default router
