import {
  leads,
  dealers,
  lead,
  leadHistory,
  graph,
  overview,
  summary,
  network,
  groupSummary,
  updateLeadApi,
  getExportLeads,
} from '@/services/lead.service'
import { searchLocations } from '@/services/location.service'
import { tableOptions } from '@/utils/table.util'

const SET_LEADS = 'SET_LEADS'
const SET_LEADS_MOBILE = 'SET_LEADS_MOBILE'
const SET_LEADS_PAGINATION = 'SET_LEADS_PAGINATION'
const SET_DEALERS = 'SET_DEALERS'
const SET_DEALERS_MOBILE = 'SET_DEALERS_MOBILE'
const SET_DEALERS_PAGINATION = 'SET_DEALERS_PAGINATION'
const SET_CURRENT_LEAD = 'SET_CURRENT_LEAD'
const SET_CURRENT_LEAD_HISTORY = 'SET_CURRENT_LEAD_HISTORY'
const SET_DASHBOARD_GRAPH = 'SET_DASHBOARD_GRAPH'
const SET_DASHBOARD_SUMMARY = 'SET_DASHBOARD_SUMMARY'
const SET_DASHBOARD_OVERVIEW = 'SET_DASHBOARD_OVERVIEW'
const SET_DASHBOARD_NETWORK = 'SET_DASHBOARD_NETWORK'
const SET_LEAD_LOCATIONS = 'SET_LEAD_LOCATIONS'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    leads: [],
    leadsMobile: [],
    leadsPagination: {},
    dealers: [],
    dealersMobile: [],
    dealersPagination: {},
    currentLead: null,
    currentLeadHistory: [],
    dashboardGraph: {},
    dashboardSummary: {},
    dashboardOverview: {},
    dashboardNetwork: {},
    leadLocations: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_LEADS]: (state, leads) => {
    state.leads = leads
  },
  [SET_LEADS_MOBILE]: (state, { leads, reset }) => {
    if (reset) {
      state.leadsMobile = []
    }
    state.leadsMobile.push(...leads)
  },
  [SET_LEADS_PAGINATION]: (state, leads) => {
    state.leadsPagination = leads
  },
  [SET_DEALERS]: (state, dealers) => {
    state.dealers = dealers
  },
  [SET_DEALERS_MOBILE]: (state, { dealers, reset }) => {
    if (reset) {
      state.dealersMobile = []
    }
    state.dealersMobile.push(...dealers)
  },
  [SET_DEALERS_PAGINATION]: (state, dealers) => {
    state.dealersPagination = dealers
  },
  [SET_CURRENT_LEAD]: (state, lead) => {
    state.currentLead = lead
  },
  [SET_CURRENT_LEAD_HISTORY]: (state, lead) => {
    state.currentLeadHistory = lead
  },
  [SET_DASHBOARD_GRAPH]: (state, graph) => {
    state.dashboardGraph = graph
  },
  [SET_DASHBOARD_SUMMARY]: (state, summary) => {
    state.dashboardSummary = summary
  },
  [SET_DASHBOARD_OVERVIEW]: (state, overview) => {
    state.dashboardOverview = overview
  },
  [SET_DASHBOARD_NETWORK]: (state, network) => {
    state.dashboardNetwork = network
  },
  [SET_LEAD_LOCATIONS]: (state, locations) => {
    state.leadLocations = locations
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  getLeads: ({ commit, rootState }, { tableParams, resetLeads, search }) => {
    if (tableParams?.filters?.length > 0) {
      tableParams.filters += ',status:1'
    } else {
      tableParams = { filters: 'status:1', ...tableParams }
    }
    const urlParams = tableOptions(tableParams, search, 'leads')

    return leads(rootState.client.currentClient.id, urlParams)
      .then(response => {
        commit(SET_LEADS, response.data.results)
        commit(SET_LEADS_MOBILE, { leads: response.data.results, reset: resetLeads })
        delete response.data.results
        commit(SET_LEADS_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getExportLeads({ rootState }, { params, search }) {
    const urlParams = tableOptions(params, search, '')
    return getExportLeads(rootState.client.currentClient.id, urlParams)
  },
  async getDealers({ commit, rootState }, { tableParams, resetLeads, search }) {
    const urlParams = tableOptions(tableParams, search, 'leads')
    const { data } = await dealers(rootState.client.currentClient.id, urlParams)
    commit(SET_DEALERS, data.results)
    commit(SET_DEALERS_MOBILE, { dealers: data.results, reset: resetLeads })
    delete data.results
    commit(SET_DEALERS_PAGINATION, data)
  },
  getLead: ({ commit, rootState }, { locationId, leadId }) => {
    return Promise.all([
      lead(rootState.client.currentClient.id, locationId, leadId),
      leadHistory(rootState.client.currentClient.id, locationId, leadId),
    ])
      .then(([lead, leadHistory]) => {
        commit(SET_CURRENT_LEAD, lead.data)
        commit(SET_CURRENT_LEAD_HISTORY, leadHistory.data.results)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },

  async updateLead({ rootState }, { locationId, leadId, data }) {
    await updateLeadApi(rootState.client.currentClient.id, locationId, leadId, data)
  },

  async getLeadLocations({ commit, rootState }) {
    const locations = await searchLocations(rootState.client.currentClient.id, { status: 1, productLocatorStatus: 1 })
    commit(SET_LEAD_LOCATIONS, locations.results)
  },

  getGraph: ({ commit, rootState }, { view, dateRange }) => {
    return graph(rootState.client.currentClient.id, view, dateRange)
      .then(response => {
        commit(SET_DASHBOARD_GRAPH, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },

  getSummary: ({ commit, rootState }, { dateRange }) => {
    return summary(rootState.client.currentClient.id, dateRange)
      .then(response => {
        commit(SET_DASHBOARD_SUMMARY, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },

  async getGroupSummary({ commit, rootState }, { tableParams }) {
    const urlParams = tableOptions(tableParams, null, 'leads')
    const { data } = await groupSummary(rootState.client.currentClient.id, urlParams)
    commit(SET_DASHBOARD_SUMMARY, data)
  },

  getOverview: ({ commit, rootState }, { dateRange, goal }) => {
    return overview(rootState.client.currentClient.id, dateRange, goal)
      .then(response => {
        commit(SET_DASHBOARD_OVERVIEW, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },

  getNetwork: ({ commit, rootState }, { dateRange }) => {
    return network(rootState.client.currentClient.id, dateRange)
      .then(response => {
        commit(SET_DASHBOARD_NETWORK, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
