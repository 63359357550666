import { sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } from '@/services/api.service'
import { API_GMB, API_LPE } from '@/config/api.config'

export const geoJsons = () => {
  return sendGetRequest('/countries.geojson')
}

export const getCategories = ({ regionCode, locale, searchTerm, view = 'basic', nextPageToken }) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.CATEGORIES}`, {
    params: {
      regionCode,
      locale,
      searchTerm,
      view,
      nextPageToken,
    },
  })
}

export const attributes = (countryCode, languageCode, categoryId, urlParams) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.ATTRIBUTES}?regionCode=${countryCode}&locale=${languageCode}&gcid=${categoryId}${urlParams}`
  )
}

export const chains = query => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.CHAINS}?query=${query}`)
}

export const accounts = (clientId, urlParams) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.ACCOUNTS}?clientId=${clientId}${urlParams}`)
}

export const getFullAccounts = urlParams => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.ACCOUNTS_FULL}${urlParams.replace('&', '?')}`)
}

export const getAccountNotifications = (clientId, account) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.ACCOUNT}`, {
    params: {
      clientId,
      account,
    },
  })
}

export const activateAndGetNotifications = (clientId, account) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.ACCOUNT_NOTIFICATION}`,
    {},
    {
      params: {
        account,
        clientId,
      },
    }
  )
}

export const listInvitations = () => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.INVITATIONS}`)
}

export const sendInvitation = (account, brandId) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.INVITATIONS}`,
    {},
    {
      params: {
        account,
        brandId,
      },
    }
  )
}

export const locations = ({ clientId, account, nextPageToken }, urlParams) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.LOCATIONS}?${urlParams}`, {
    params: {
      clientId,
      account,
      nextPageToken,
    },
  })
}

export const location = (clientId, locationName, account, period = '') => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.LOCATION2}?clientId=${clientId}&id=${locationName}&account=${account}&period=${period}`
  )
}

export const locationUpdate = (clientId, locationName, account, data) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.LOCATION2}?clientId=${clientId}&id=${locationName}&account=${account}`,
    data
  )
}

export const getServicesFromCategory = (regionCode, locale, categoryId) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.SERVICES}?regionCode=${regionCode}&locale=${locale}&categoryId=${categoryId}`
  )
}

export const googleUpdatesLocations = ({ clientId, account }, urlParams) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.LOCATION_GOOGLE_UPDATES}?${urlParams}`, {
    params: {
      clientId,
      account,
    },
  })
}

export const GUAdministratives = (id, clientId) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.UPDATE_HISTORY}`, {
    params: {
      id,
      clientId,
    },
  })
}

export const postGUAdministrative = (id, clientId, status) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.UPDATE_HISTORY}?id=${id}&clientId=${clientId}&status=${status}`
  )
}

export const GUAttributs = (id, clientId) => {
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.UPDATE_ATTRIBUTE_HISTORY}`, {
    params: {
      id,
      clientId,
    },
  })
}

export const postGUAttribut = (id, clientId, status) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.UPDATE_ATTRIBUTE_HISTORY}?id=${id}&clientId=${clientId}&value=${status}`
  )
}

export const locationMedias = (clientId, locationName, account) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.MEDIA}?clientId=${clientId}&id=${locationName}&account=${account}`
  )
}

export const getPublicHolidaysApi = (locale, from, length) => {
  return sendGetRequest(`${API_GMB.HOLIDAYS}countryCode=${locale}&from=${from}&length=${length}`)
}

export const recordFlagEvent = (clientId, locationName, account) => {
  return sendPutRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.MEDIA_CUSTOMER}`,
    {},
    {
      params: {
        clientId,
        id: locationName,
        account,
      },
    }
  )
}

export const deleteLocationMedia = (clientId, account, id) => {
  return sendDeleteRequest(`${API_GMB.BASE_API_URL}${API_GMB.MEDIA_ID}`, {
    params: {
      clientId,
      id,
      account,
    },
  })
}

export const importPicture = ({ clientId, account, locationName, mediaCategory, name, file }) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.MEDIA_ID}`,
    { target: mediaCategory, name, file },
    { params: { clientId, id: locationName, account } }
  )
}

export const gmbAvailables = clientId => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.GMB_LOCATION_WITHOUT_ASSOCATION}?clientId=${clientId}`)
}

export const updateGmbBindLocations = (clientId, id, data) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.GMB_LOCATION_ID_MAPPING}?clientId=${clientId}&id=${id}`,
    data
  )
}

export const getGmbBindLocations = (clientId, id) => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.GMB_LOCATION_ID_MAPPING}?clientId=${clientId}&id=${id}`)
}

export const getLocalPosts = ({ clientId, account, location, page, size }) => {
  const params = {
    clientId,
    account,
  }
  if (location != null) params.location = location
  if (page != null) params.page = page
  if (size != null) params.size = size
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS}`, {
    params,
  })
}

export const getLocalPostsDetail = ({ clientId, account, location, id, page, size }) => {
  const params = {
    clientId,
    account,
    location,
    id,
  }
  if (page != null) params.page = page
  if (size != null) params.size = size
  return sendGetRequest(`${API_GMB.BASE_API_URL}${API_GMB.QUESTIONS_ANSWERS_ID}`, { params })
}
