import { tableOptions } from '@/utils/table.util'
import displayErrorLogNoProduction from '../../utils/displayErrorLogNoProduction'
import { listLocalPosts } from '../../services/localPost.service'

const SET_CURRENT_LOCAL_POSTS = 'SET_CURRENT_LOCAL_POSTS'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    currentLocalPosts: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_CURRENT_LOCAL_POSTS]: (state, data) => {
    state.currentLocalPosts = data
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  loadLocalPosts: async ({ commit, rootState }, { account, tableParams, search }) => {
    try {
      const urlParams = tableOptions(tableParams, search, 'simpleSearch')
      const { data } = await listLocalPosts(rootState.client.currentClient.id, account, urlParams)
      const { results, ...rest } = data
      commit(SET_CURRENT_LOCAL_POSTS, {
        data: results,
        pagination: rest,
      })
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
