<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  mounted() {
    if (this.darkTheme) {
      document.documentElement.setAttribute('data-theme', 'dark')
    } else {
      document.documentElement.removeAttribute('data-theme')
    }
  },
  computed: {
    ...mapState({
      darkTheme: state => state.backoffice.darkTheme,
      currentClientId: state => state.client.currentClient?.id,
    }),
  },
}
</script>

<style lang="scss">
@tailwind base; /* stylelint-disable-line scss/at-rule-no-unknown */
@tailwind components; /* stylelint-disable-line scss/at-rule-no-unknown */
@tailwind utilities; /* stylelint-disable-line scss/at-rule-no-unknown */

#app {
  height: 100%;
}
</style>
