import { sendGetRequest, sendPostRequest, sendPutRequest } from '@/services/api.service'
import { API_LPE, API_ALGOLIA, GTR_CORE } from '@/config/api.config'

export const frontOffices = (clientId, urlParams, locationId) => {
  return sendGetRequest(
    `${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_FRONT_OFFICE_LIST}?clientId=${clientId}${
      locationId ? `&locationId=${locationId}` : ''
    }${urlParams}`
  )
}

export const frontOffice = urlParams => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_PRIVATE_FRONT_OFFICE}${urlParams}`)
}

export const frontOfficeUpdate = (clientId, frontOfficeId, localeId, localeDefault, data) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.FRONT_OFFICE}?clientId=${clientId}&id=${frontOfficeId}&localeId=${localeId}&default=${localeDefault}`,
    data
  )
}

export const frontOfficeCreate = (clientId, data) => {
  return sendPutRequest(`${API_LPE.BASE_API_URL}${API_LPE.FRONT_OFFICE}?clientId=${clientId}`, data)
}

export const frontOfficeIndice = (id, clientId) => {
  return sendGetRequest(`${API_ALGOLIA.BASE_API_URL}${API_ALGOLIA.FRONT_OFFICE_INDICE}?id=${id}&clientId=${clientId}`)
}

export const frontOfficeFeatureList = (clientId, frontOfficeId) => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}front-office_id_factory.php?id=${frontOfficeId}&clientId=${clientId}`)
}

export const frontOfficeSEOCharCounter = (clientId, id, localeId) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.FRONT_OFFICE_SEO_CHAR_COUNTER}?clientId=${clientId}&id=${id}&localeId=${localeId}`
  )
}
