import { getAppUrl } from '@/services/radar.service'

const getDefaultState = () => {
  return {
    appUrl: '',
  }
}

const state = getDefaultState()

const SET_APP_URL = 'SET_APP_URL'
const RESET_STATE = 'RESET_STATE'

const mutations = {
  [SET_APP_URL]: (state, appUrl) => {
    state.appUrl = appUrl
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  getAppUrl: async ({ commit, rootState }) => {
    const appUrl = await getAppUrl(rootState.client.currentClient.id)
    commit(SET_APP_URL, appUrl)
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
