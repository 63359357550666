import { sendGetRequest } from '@/services/api.service'
import { GTR_CORE } from '@/config/api.config'

export const getClientApi = uuid => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_CLIENT}/${uuid}`)
}

export const getLocalesApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_LOCALE}?size=200&search=status:1`)
}

export const getClientFeatureListApi = clientId => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.FEATURE_LIST}?client.id=${clientId}`)
}
