import {
  ohGroup,
  ohGroups,
  ohSubgroups,
  ohGroupCountries,
  ohGroupCreate,
  ohSubgroupCreate,
  ohGroupUpdate,
  ohSubgroupUpdate,
} from '@/services/ohgroup.service'
import { tableOptions } from '@/utils/table.util'
import { orderOpeningHoursObject } from '@/utils/date.util'

const SET_OH_GROUPS = 'SET_OH_GROUPS'
const SET_OH_GROUPS_MOBILE = 'SET_OH_GROUPS_MOBILE'
const SET_OH_GROUPS_PAGINATION = 'SET_OH_GROUPS_PAGINATION'
const SET_CURRENT_OH_GROUP = 'SET_CURRENT_OH_GROUP'
const SET_OH_SUBGROUPS = 'SET_OH_SUBGROUPS'
const SET_OH_GROUP_COUNTRIES = 'SET_OH_GROUP_COUNTRIES'
const RESET_OH_GROUP_SUBGROUPS = 'RESET_OH_GROUP_SUBGROUPS'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ohGroups: [],
    ohGroupsMobile: [],
    ohGroupsPagination: {},
    currentOhGroup: {},
    ohSubgroups: [],
    ohGroupCountries: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_OH_GROUPS]: (state, ohGroups) => {
    state.ohGroups = ohGroups
  },
  [SET_OH_GROUPS_MOBILE]: (state, { ohGroups, reset }) => {
    if (reset) {
      state.ohGroupsMobile = []
    }
    state.ohGroupsMobile.push(...ohGroups)
  },
  [SET_OH_GROUPS_PAGINATION]: (state, pagination) => {
    state.ohGroupsPagination = pagination
  },
  [SET_CURRENT_OH_GROUP]: (state, ohGroup) => {
    state.currentOhGroup = {
      ...ohGroup,
      hours: orderOpeningHoursObject(ohGroup.hours),
    }
  },
  [SET_OH_SUBGROUPS]: (state, ohSubgroups) => {
    state.ohSubgroups = ohSubgroups.map(ohSubgroup => ({
      ...ohSubgroup,
      hours: orderOpeningHoursObject(ohSubgroup.hours),
    }))
  },
  [SET_OH_GROUP_COUNTRIES]: (state, countries) => {
    state.ohGroupCountries = countries
  },
  [RESET_OH_GROUP_SUBGROUPS]: state => {
    state.currentOhGroup = {}
    state.ohSubgroups = []
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  getOhGroups: ({ commit, rootState }, { tableParams, resetOhGroup }) => {
    const urlParams = tableOptions(tableParams, '', 'ohGroup')
    return ohGroups(rootState.client.currentClient.id, urlParams)
      .then(response => {
        commit(SET_OH_GROUPS, response.data.results)
        commit(SET_OH_GROUPS_MOBILE, { ohGroups: response.data.results, reset: resetOhGroup })
        delete response.data.results
        commit(SET_OH_GROUPS_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getOhGroup: ({ commit, rootState }, { ohGroupId }) => {
    return ohGroup(rootState.client.currentClient.id, ohGroupId)
      .then(response => {
        commit(SET_CURRENT_OH_GROUP, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getOhSubgroups: ({ commit, rootState }, { ohGroupId }) => {
    return ohSubgroups(rootState.client.currentClient.id, ohGroupId)
      .then(response => {
        commit(SET_OH_SUBGROUPS, response.data.results.results)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getOhGroupCountries: ({ commit, rootState }) => {
    return ohGroupCountries(rootState.client.currentClient.id)
      .then(response => {
        commit(SET_OH_GROUP_COUNTRIES, response.data.results)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  createOhGroup: ({ commit, rootState }, ohGroup) => {
    const ohGroupData = {
      type: 'G',
      clientId: rootState.client.currentClient.id,
      name: ohGroup.name,
      countries: ohGroup.countries.map(country => country.countryCode),
      weekdays: ohGroup.weekdays.map(day => day.key),
      hours: ohGroup.hours,
    }
    return ohGroupCreate(rootState.client.currentClient.id, ohGroupData)
      .then(response => {
        commit(SET_CURRENT_OH_GROUP, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  createOhSubgroup: ({ state, rootState }, ohSubgroups) => {
    const ohSubgroupData = {
      type: 'S',
      parentId: state.currentOhGroup.id,
      clientId: rootState.client.currentClient.id,
      name: ohSubgroups.name,
      weekdays: state.currentOhGroup.weekdays,
      hours: ohSubgroups.hours,
      default: ohSubgroups.default,
    }
    return ohSubgroupCreate(rootState.client.currentClient.id, ohSubgroupData)
      .then(() => {
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  updateOhGroup: ({ commit, rootState }, ohGroup) => {
    const ohGroupData = {
      ...ohGroup,
      countries: ohGroup.countries.map(country => country.countryCode),
    }
    return ohGroupUpdate(rootState.client.currentClient.id, ohGroup.id, ohGroupData)
      .then(response => {
        commit(SET_CURRENT_OH_GROUP, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  updateOhSubgroup: ({ state, rootState }, ohSubgroups) => {
    if (ohSubgroups.id) {
      const ohSubroupData = {
        ...ohSubgroups,
        weekdays: state.currentOhGroup.weekdays,
      }
      return ohSubgroupUpdate(rootState.client.currentClient.id, ohSubgroups.id, ohSubroupData)
        .then(() => {
          return Promise.resolve()
        })
        .catch(e => Promise.reject(e))
    } else {
      const ohSubgroupData = {
        type: 'S',
        parentId: state.currentOhGroup.id,
        clientId: rootState.client.currentClient.id,
        name: ohSubgroups.name,
        weekdays: state.currentOhGroup.weekdays,
        hours: ohSubgroups.hours,
        default: ohSubgroups.default,
      }
      return ohSubgroupCreate(rootState.client.currentClient.id, ohSubgroupData)
        .then(() => {
          return Promise.resolve()
        })
        .catch(e => Promise.reject(e))
    }
  },
  resetOhGroupAndSubgroups: ({ commit }) => {
    commit(RESET_OH_GROUP_SUBGROUPS)
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
