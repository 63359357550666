import store from '@/store'

export const userRoles = {
  superAdmin: 'sa',
  brandAdmin: 'brand_admin',
  agency: 'agency',
  regionalAdmin: 'regional_admin',
  groupAdmin: 'group_admin',
  location: 'location',
}

export const isUserAuthorized = allowedUsers => {
  return allowedUsers ? allowedUsers.includes(store.state.backoffice.currentUser.type) : true
}
