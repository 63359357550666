import { sendGetRequest, sendPutRequest, sendPostRequest } from '@/services/api.service'
import { API_LPE } from '@/config/api.config'

export const ohGroups = (clientId, urlParams) => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.OH_GROUP}?clientId=${clientId}${urlParams}`)
}

export const ohGroup = (clientId, id) => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.OH_GROUP_ID}?clientId=${clientId}&id=${id}`)
}

export const ohSubgroups = (clientId, ohGroupId) => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.OH_GROUP_SUBGROUP}?clientId=${clientId}&groupId=${ohGroupId}`)
}

export const ohGroupCountries = clientId => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.OH_GROUP_COUNTRIES}?clientId=${clientId}&status=1`)
}

export const ohGroupCreate = (clientId, data) => {
  return sendPutRequest(`${API_LPE.BASE_API_URL}${API_LPE.OH_GROUP}?clientId=${clientId}`, data)
}

export const ohSubgroupCreate = (clientId, data) => {
  return sendPutRequest(`${API_LPE.BASE_API_URL}${API_LPE.OH_GROUP_SUBGROUP}?clientId=${clientId}`, data)
}

export const ohGroupUpdate = (clientId, ohGroupId, data) => {
  return sendPostRequest(`${API_LPE.BASE_API_URL}${API_LPE.OH_GROUP_ID}?clientId=${clientId}&id=${ohGroupId}`, data)
}

export const ohSubgroupUpdate = (clientId, ohSubGroupId, data) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.OH_GROUP_SUBGROUP_ID}?clientId=${clientId}&id=${ohSubGroupId}`,
    data
  )
}
