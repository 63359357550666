import {
  listQuestionsAnswers,
  getQuestionsAnswersDetail,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  createAnswer,
  updateAnswer,
  deleteAnswer,
} from '../../services/qa.service'
import { tableOptions } from '@/utils/table.util'
import displayErrorLogNoProduction from '../../utils/displayErrorLogNoProduction'

const SET_CURRENT_QUESTIONS_ANSWERS = 'SET_CURRENT_QUESTIONS_ANSWERS'
const SET_CURRENT_QUESTIONS_ANSWERS_DETAIL = 'SET_CURRENT_QUESTIONS_ANSWERS_DETAIL'
const ADD_QUESTION = 'ADD_QUESTION'
const UPDATE_QUESTION = 'UPDATE_QUESTION'
const DELETE_QUESTION = 'DELETE_QUESTION'
const ADD_RESPONSE = 'ADD_RESPONSE'
const UPDATE_RESPONSE = 'UPDATE_RESPONSE'
const DELETE_RESPONSE = 'DELETE_RESPONSE'
const RESET_QUESTION = 'RESET_QUESTION'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    currentQuestionsAnswers: { data: [] },
    currentQuestionsAnswersDetail: null,
  }
}

const state = getDefaultState()

const updatedQuestions = (questions, updatedQuestion) => {
  const questionsCpy = [...questions]
  questionsCpy[questionsCpy.findIndex(question => question.name === updatedQuestion.name)] = updatedQuestion
  return questionsCpy
}

const addedResponse = (questions, newResponse) => {
  const questionsCpy = [...questions]
  const questionIndex = questionsCpy.findIndex(question => question.name === newResponse.name.split('/answers')[0])
  questionsCpy[questionIndex].topAnswers = [...questionsCpy[questionIndex].topAnswers, newResponse]
  return questionsCpy
}

const updatedResponses = (questions, updatedResponse) => {
  const questionIndex = questions.findIndex(question => question.name === updatedResponse.name.split('/answers')[0])
  const responseIndex = questions[questionIndex].topAnswers.findIndex(
    response => response.name === updatedResponse.name
  )
  const questionsCpy = [...questions]
  questionsCpy[questionIndex].topAnswers[responseIndex] = updatedResponse
  return questionsCpy
}

const deletedResponse = (questions, responseNameToDelete) => {
  const questionsCpy = [...questions]
  const questionIndex = questionsCpy.findIndex(question => question.name === responseNameToDelete.split('/answers')[0])
  questionsCpy[questionIndex].topAnswers = questionsCpy[questionIndex].topAnswers.filter(
    response => response.name !== responseNameToDelete
  )
  return questionsCpy
}

const mutations = {
  [SET_CURRENT_QUESTIONS_ANSWERS]: (state, questionsAnswers) => {
    state.currentQuestionsAnswers = questionsAnswers
  },
  [SET_CURRENT_QUESTIONS_ANSWERS_DETAIL]: (state, questionsAnswersDetail) => {
    state.currentQuestionsAnswersDetail = questionsAnswersDetail
  },
  [ADD_QUESTION]: (state, data) => {
    state.currentQuestionsAnswers.data = [...state.currentQuestionsAnswers.data, data]
    state.currentQuestionsAnswersDetail = data
  },
  [UPDATE_QUESTION]: (state, data) => {
    state.currentQuestionsAnswers.data = updatedQuestions(state.currentQuestionsAnswers.data, data)
    state.currentQuestionsAnswersDetail = data
  },
  [RESET_QUESTION]: state => {
    state.currentQuestionsAnswersDetail = null
  },
  [DELETE_QUESTION]: (state, id) => {
    state.currentQuestionsAnswersDetail = null
    state.currentQuestionsAnswers.data = state.currentQuestionsAnswers.data.filter(question => question.name !== id)
  },
  [ADD_RESPONSE]: (state, data) => {
    state.currentQuestionsAnswers.data = addedResponse(state.currentQuestionsAnswers.data, data)
    state.currentQuestionsAnswersDetail = {
      ...state.currentQuestionsAnswersDetail,
      topAnswers: [...state.currentQuestionsAnswersDetail.topAnswers, data],
    }
  },
  [UPDATE_RESPONSE]: (state, data) => {
    state.currentQuestionsAnswers.data = updatedResponses(state.currentQuestionsAnswers.data, data)
    state.currentQuestionsAnswersDetail = updatedResponses([state.currentQuestionsAnswersDetail], data)[0]
  },
  [DELETE_RESPONSE]: (state, id) => {
    state.currentQuestionsAnswers.data = deletedResponse(state.currentQuestionsAnswers.data, id)
    const result = deletedResponse([state.currentQuestionsAnswersDetail], id)
    state.currentQuestionsAnswersDetail = result.length > 0 ? result[0] : null
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  loadQuestionsAnswers: async ({ commit, rootState }, { account, location = null, tableParams, search }) => {
    try {
      const urlParams = tableOptions(tableParams, search, 'qa')
      const { data } = await listQuestionsAnswers(rootState.client.currentClient.id, account, location, urlParams)
      const { results, ...rest } = data
      commit(SET_CURRENT_QUESTIONS_ANSWERS, {
        data: results,
        pagination: rest,
      })
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  getQuestionsAnswersDetail: async ({ commit, rootState }, { account, location, id, page, size }) => {
    try {
      const { data } = await getQuestionsAnswersDetail({
        clientId: rootState.client.currentClient.id,
        account,
        location,
        id,
        page,
        size,
      })
      commit(SET_CURRENT_QUESTIONS_ANSWERS_DETAIL, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  createQuestion: async ({ commit, rootState }, { account, location, text }) => {
    try {
      const { data } = await createQuestion({
        clientId: rootState.client.currentClient.id,
        account,
        location,
        text,
      })
      commit(ADD_QUESTION, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  updateQuestion: async ({ commit, rootState }, { id, account, location, text }) => {
    try {
      const { data } = await updateQuestion({
        clientId: rootState.client.currentClient.id,
        account,
        location,
        text,
        id,
      })
      commit(UPDATE_QUESTION, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  deleteQuestion: async ({ commit, rootState }, { account, location, id, text }) => {
    try {
      await deleteQuestion({ clientId: rootState.client.currentClient.id, account, location, id, text })
      commit(DELETE_QUESTION, id)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  resetQuestion: async ({ commit }) => {
    commit(RESET_QUESTION)
  },
  createAnswer: async ({ commit, rootState }, { account, location, id, text }) => {
    try {
      const { data } = await createAnswer({
        clientId: rootState.client.currentClient.id,
        account,
        location,
        id,
        text,
      })
      commit(ADD_RESPONSE, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  updateAnswer: async ({ commit, rootState }, { account, location, id, text }) => {
    try {
      const { data } = await updateAnswer({
        clientId: rootState.client.currentClient.id,
        account,
        location,
        id,
        text,
      })
      commit(UPDATE_RESPONSE, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  deleteAnswer: async ({ commit, rootState }, { account, location, id, text }) => {
    try {
      await deleteAnswer({ clientId: rootState.client.currentClient.id, account, location, id, text })
      commit(DELETE_RESPONSE, id)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
