export const countries = Object.freeze({
  en: [
    {
      name: 'Afghanistan',
      countryCode: 'AF',
    },
    {
      name: 'Albania',
      countryCode: 'AL',
    },
    {
      name: 'Algeria',
      countryCode: 'DZ',
    },
    {
      name: 'American Samoa',
      countryCode: 'AS',
    },
    {
      name: 'Andorra',
      countryCode: 'AD',
    },
    {
      name: 'Angola',
      countryCode: 'AO',
    },
    {
      name: 'Anguilla',
      countryCode: 'AI',
    },
    {
      name: 'Antarctica',
      countryCode: 'AQ',
    },
    {
      name: 'Antigua and Barbuda',
      countryCode: 'AG',
    },
    {
      name: 'Argentina',
      countryCode: 'AR',
    },
    {
      name: 'Armenia',
      countryCode: 'AM',
    },
    {
      name: 'Aruba',
      countryCode: 'AW',
    },
    {
      name: 'Australia',
      countryCode: 'AU',
    },
    {
      name: 'Austria',
      countryCode: 'AT',
    },
    {
      name: 'Azerbaijan',
      countryCode: 'AZ',
    },
    {
      name: 'Bahamas',
      countryCode: 'BS',
    },
    {
      name: 'Bahrain',
      countryCode: 'BH',
    },
    {
      name: 'Bangladesh',
      countryCode: 'BD',
    },
    {
      name: 'Barbados',
      countryCode: 'BB',
    },
    {
      name: 'Belarus',
      countryCode: 'BY',
    },
    {
      name: 'Belgium',
      countryCode: 'BE',
    },
    {
      name: 'Belize',
      countryCode: 'BZ',
    },
    {
      name: 'Benin',
      countryCode: 'BJ',
    },
    {
      name: 'Bermuda',
      countryCode: 'BM',
    },
    {
      name: 'Bhutan',
      countryCode: 'BT',
    },
    {
      name: 'Bolivia',
      countryCode: 'BO',
    },
    {
      name: 'Bonaire, Saint Eustatius, and Saba',
      countryCode: 'BQ',
    },
    {
      name: 'Bosnia and Herzegovina',
      countryCode: 'BA',
    },
    {
      name: 'Botswana',
      countryCode: 'BW',
    },
    {
      name: 'Bouvet Island',
      countryCode: 'BV',
    },
    {
      name: 'Brazil',
      countryCode: 'BR',
    },
    {
      name: 'British Indian Ocean Territory',
      countryCode: 'IO',
    },
    {
      name: 'British Virgin Islands',
      countryCode: 'VG',
    },
    {
      name: 'Brunei',
      countryCode: 'BN',
    },
    {
      name: 'Bulgaria',
      countryCode: 'BG',
    },
    {
      name: 'Burkina Faso',
      countryCode: 'BF',
    },
    {
      name: 'Burundi',
      countryCode: 'BI',
    },
    {
      name: 'Cabo Verde',
      countryCode: 'CV',
    },
    {
      name: 'Cambodia',
      countryCode: 'KH',
    },
    {
      name: 'Cameroon',
      countryCode: 'CM',
    },
    {
      name: 'Canada',
      countryCode: 'CA',
    },
    {
      name: 'Cayman Islands',
      countryCode: 'KY',
    },
    {
      name: 'Central African Republic',
      countryCode: 'CF',
    },
    {
      name: 'Chad',
      countryCode: 'TD',
    },
    {
      name: 'Chile',
      countryCode: 'CL',
    },
    {
      name: 'China',
      countryCode: 'CN',
    },
    {
      name: 'Christmas Island',
      countryCode: 'CX',
    },
    {
      name: 'Cocos [Keeling] Islands',
      countryCode: 'CC',
    },
    {
      name: 'Colombia',
      countryCode: 'CO',
    },
    {
      name: 'Comoros',
      countryCode: 'KM',
    },
    {
      name: 'Congo',
      countryCode: 'CD',
    },
    {
      name: 'Congo',
      countryCode: 'CG',
    },
    {
      name: 'Cook Islands',
      countryCode: 'CK',
    },
    {
      name: 'Costa Rica',
      countryCode: 'CR',
    },
    {
      name: 'Croatia',
      countryCode: 'HR',
    },
    {
      name: 'Cuba',
      countryCode: 'CU',
    },
    {
      name: 'CuraÃ§ao',
      countryCode: 'CW',
    },
    {
      name: 'Cyprus',
      countryCode: 'CY',
    },
    {
      name: 'Czechia',
      countryCode: 'CZ',
    },
    {
      name: 'Denmark',
      countryCode: 'DK',
    },
    {
      name: 'Djibouti',
      countryCode: 'DJ',
    },
    {
      name: 'Dominica',
      countryCode: 'DM',
    },
    {
      name: 'Dominican Republic',
      countryCode: 'DO',
    },
    {
      name: 'East Timor',
      countryCode: 'TL',
    },
    {
      name: 'Ecuador',
      countryCode: 'EC',
    },
    {
      name: 'Egypt',
      countryCode: 'EG',
    },
    {
      name: 'El Salvador',
      countryCode: 'SV',
    },
    {
      name: 'Equatorial Guinea',
      countryCode: 'GQ',
    },
    {
      name: 'Eritrea',
      countryCode: 'ER',
    },
    {
      name: 'Estonia',
      countryCode: 'EE',
    },
    {
      name: 'Eswatini',
      countryCode: 'SZ',
    },
    {
      name: 'Ethiopia',
      countryCode: 'ET',
    },
    {
      name: 'Falkland Islands',
      countryCode: 'FK',
    },
    {
      name: 'Faroe Islands',
      countryCode: 'FO',
    },
    {
      name: 'Fiji',
      countryCode: 'FJ',
    },
    {
      name: 'Finland',
      countryCode: 'FI',
    },
    {
      name: 'France',
      countryCode: 'FR',
    },
    {
      name: 'French Guiana',
      countryCode: 'GF',
    },
    {
      name: 'French Polynesia',
      countryCode: 'PF',
    },
    {
      name: 'French Southern Territories',
      countryCode: 'TF',
    },
    {
      name: 'Gabon',
      countryCode: 'GA',
    },
    {
      name: 'Gambia',
      countryCode: 'GM',
    },
    {
      name: 'Georgia',
      countryCode: 'GE',
    },
    {
      name: 'Germany',
      countryCode: 'DE',
    },
    {
      name: 'Ghana',
      countryCode: 'GH',
    },
    {
      name: 'Gibraltar',
      countryCode: 'GI',
    },
    {
      name: 'Great Britain',
      countryCode: 'GB',
    },
    {
      name: 'Greece',
      countryCode: 'GR',
    },
    {
      name: 'Greenland',
      countryCode: 'GL',
    },
    {
      name: 'Grenada',
      countryCode: 'GD',
    },
    {
      name: 'Guadeloupe',
      countryCode: 'GP',
    },
    {
      name: 'Guam',
      countryCode: 'GU',
    },
    {
      name: 'Guatemala',
      countryCode: 'GT',
    },
    {
      name: 'Guernsey',
      countryCode: 'GG',
    },
    {
      name: 'Guinea',
      countryCode: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      countryCode: 'GW',
    },
    {
      name: 'Guyana',
      countryCode: 'GY',
    },
    {
      name: 'Haiti',
      countryCode: 'HT',
    },
    {
      name: 'Hashemite Kingdom of Jordan',
      countryCode: 'JO',
    },
    {
      name: 'Heard Island and McDonald Islands',
      countryCode: 'HM',
    },
    {
      name: 'Honduras',
      countryCode: 'HN',
    },
    {
      name: 'Hong Kong',
      countryCode: 'HK',
    },
    {
      name: 'Hungary',
      countryCode: 'HU',
    },
    {
      name: 'Iceland',
      countryCode: 'IS',
    },
    {
      name: 'India',
      countryCode: 'IN',
    },
    {
      name: 'Indonesia',
      countryCode: 'ID',
    },
    {
      name: 'Iran',
      countryCode: 'IR',
    },
    {
      name: 'Iraq',
      countryCode: 'IQ',
    },
    {
      name: 'Ireland',
      countryCode: 'IE',
    },
    {
      name: 'Isle of Man',
      countryCode: 'IM',
    },
    {
      name: 'Israel',
      countryCode: 'IL',
    },
    {
      name: 'Italy',
      countryCode: 'IT',
    },
    {
      name: 'Ivory Coast',
      countryCode: 'CI',
    },
    {
      name: 'Jamaica',
      countryCode: 'JM',
    },
    {
      name: 'Japan',
      countryCode: 'JP',
    },
    {
      name: 'Jersey',
      countryCode: 'JE',
    },
    {
      name: 'Kazakhstan',
      countryCode: 'KZ',
    },
    {
      name: 'Kenya',
      countryCode: 'KE',
    },
    {
      name: 'Kiribati',
      countryCode: 'KI',
    },
    {
      name: 'Kosovo',
      countryCode: 'XK',
    },
    {
      name: 'Kuwait',
      countryCode: 'KW',
    },
    {
      name: 'Kyrgyzstan',
      countryCode: 'KG',
    },
    {
      name: 'Laos',
      countryCode: 'LA',
    },
    {
      name: 'Latvia',
      countryCode: 'LV',
    },
    {
      name: 'Lebanon',
      countryCode: 'LB',
    },
    {
      name: 'Lesotho',
      countryCode: 'LS',
    },
    {
      name: 'Liberia',
      countryCode: 'LR',
    },
    {
      name: 'Libya',
      countryCode: 'LY',
    },
    {
      name: 'Liechtenstein',
      countryCode: 'LI',
    },
    {
      name: 'Lithuania',
      countryCode: 'LT',
    },
    {
      name: 'Luxembourg',
      countryCode: 'LU',
    },
    {
      name: 'Macao',
      countryCode: 'MO',
    },
    {
      name: 'Madagascar',
      countryCode: 'MG',
    },
    {
      name: 'Malawi',
      countryCode: 'MW',
    },
    {
      name: 'Malaysia',
      countryCode: 'MY',
    },
    {
      name: 'Maldives',
      countryCode: 'MV',
    },
    {
      name: 'Mali',
      countryCode: 'ML',
    },
    {
      name: 'Malta',
      countryCode: 'MT',
    },
    {
      name: 'Marshall Islands',
      countryCode: 'MH',
    },
    {
      name: 'Martinique',
      countryCode: 'MQ',
    },
    {
      name: 'Mauritania',
      countryCode: 'MR',
    },
    {
      name: 'Mauritius',
      countryCode: 'MU',
    },
    {
      name: 'Mayotte',
      countryCode: 'YT',
    },
    {
      name: 'Mexico',
      countryCode: 'MX',
    },
    {
      name: 'Micronesia',
      countryCode: 'FM',
    },
    {
      name: 'Moldova',
      countryCode: 'MD',
    },
    {
      name: 'Monaco',
      countryCode: 'MC',
    },
    {
      name: 'Mongolia',
      countryCode: 'MN',
    },
    {
      name: 'Montenegro',
      countryCode: 'ME',
    },
    {
      name: 'Montserrat',
      countryCode: 'MS',
    },
    {
      name: 'Morocco',
      countryCode: 'MA',
    },
    {
      name: 'Mozambique',
      countryCode: 'MZ',
    },
    {
      name: 'Myanmar',
      countryCode: 'MM',
    },
    {
      name: 'Namibia',
      countryCode: 'NA',
    },
    {
      name: 'Nauru',
      countryCode: 'NR',
    },
    {
      name: 'Nepal',
      countryCode: 'NP',
    },
    {
      name: 'Netherlands',
      countryCode: 'NL',
    },
    {
      name: 'New Caledonia',
      countryCode: 'NC',
    },
    {
      name: 'New Zealand',
      countryCode: 'NZ',
    },
    {
      name: 'Nicaragua',
      countryCode: 'NI',
    },
    {
      name: 'Niger',
      countryCode: 'NE',
    },
    {
      name: 'Nigeria',
      countryCode: 'NG',
    },
    {
      name: 'Niue',
      countryCode: 'NU',
    },
    {
      name: 'Norfolk Island',
      countryCode: 'NF',
    },
    {
      name: 'North Korea',
      countryCode: 'KP',
    },
    {
      name: 'North Macedonia',
      countryCode: 'MK',
    },
    {
      name: 'Northern Mariana Islands',
      countryCode: 'MP',
    },
    {
      name: 'Norway',
      countryCode: 'NO',
    },
    {
      name: 'Oman',
      countryCode: 'OM',
    },
    {
      name: 'Pakistan',
      countryCode: 'PK',
    },
    {
      name: 'Palau',
      countryCode: 'PW',
    },
    {
      name: 'Palestine',
      countryCode: 'PS',
    },
    {
      name: 'Panama',
      countryCode: 'PA',
    },
    {
      name: 'Papua New Guinea',
      countryCode: 'PG',
    },
    {
      name: 'Paraguay',
      countryCode: 'PY',
    },
    {
      name: 'Peru',
      countryCode: 'PE',
    },
    {
      name: 'Philippines',
      countryCode: 'PH',
    },
    {
      name: 'Pitcairn Islands',
      countryCode: 'PN',
    },
    {
      name: 'Poland',
      countryCode: 'PL',
    },
    {
      name: 'Portugal',
      countryCode: 'PT',
    },
    {
      name: 'Puerto Rico',
      countryCode: 'PR',
    },
    {
      name: 'Qatar',
      countryCode: 'QA',
    },
    {
      name: 'Republic of Korea',
      countryCode: 'KR',
    },
    {
      name: 'Republic of South Sudan',
      countryCode: 'SS',
    },
    {
      name: 'Romania',
      countryCode: 'RO',
    },
    {
      name: 'Russia',
      countryCode: 'RU',
    },
    {
      name: 'Rwanda',
      countryCode: 'RW',
    },
    {
      name: 'RÃ©union',
      countryCode: 'RE',
    },
    {
      name: 'Saint BarthÃ©lemy',
      countryCode: 'BL',
    },
    {
      name: 'Saint Helena',
      countryCode: 'SH',
    },
    {
      name: 'Saint Lucia',
      countryCode: 'LC',
    },
    {
      name: 'Saint Martin',
      countryCode: 'MF',
    },
    {
      name: 'Saint Pierre and Miquelon',
      countryCode: 'PM',
    },
    {
      name: 'Samoa',
      countryCode: 'WS',
    },
    {
      name: 'San Marino',
      countryCode: 'SM',
    },
    {
      name: 'Saudi Arabia',
      countryCode: 'SA',
    },
    {
      name: 'Senegal',
      countryCode: 'SN',
    },
    {
      name: 'Serbia',
      countryCode: 'RS',
    },
    {
      name: 'Seychelles',
      countryCode: 'SC',
    },
    {
      name: 'Sierra Leone',
      countryCode: 'SL',
    },
    {
      name: 'Singapore',
      countryCode: 'SG',
    },
    {
      name: 'Sint Maarten',
      countryCode: 'SX',
    },
    {
      name: 'Slovakia',
      countryCode: 'SK',
    },
    {
      name: 'Slovenia',
      countryCode: 'SI',
    },
    {
      name: 'Solomon Islands',
      countryCode: 'SB',
    },
    {
      name: 'Somalia',
      countryCode: 'SO',
    },
    {
      name: 'South Africa',
      countryCode: 'ZA',
    },
    {
      name: 'South Georgia and South Sandwich Islands',
      countryCode: 'GS',
    },
    {
      name: 'Spain',
      countryCode: 'ES',
    },
    {
      name: 'Sri Lanka',
      countryCode: 'LK',
    },
    {
      name: 'St Kitts and Nevis',
      countryCode: 'KN',
    },
    {
      name: 'St Vincent and Grenadines',
      countryCode: 'VC',
    },
    {
      name: 'Sudan',
      countryCode: 'SD',
    },
    {
      name: 'Suriname',
      countryCode: 'SR',
    },
    {
      name: 'Svalbard and Jan Mayen',
      countryCode: 'SJ',
    },
    {
      name: 'Sweden',
      countryCode: 'SE',
    },
    {
      name: 'Switzerland',
      countryCode: 'CH',
    },
    {
      name: 'Syria',
      countryCode: 'SY',
    },
    {
      name: 'SÃ£o TomÃ© and PrÃ­ncipe',
      countryCode: 'ST',
    },
    {
      name: 'Taiwan',
      countryCode: 'TW',
    },
    {
      name: 'Tajikistan',
      countryCode: 'TJ',
    },
    {
      name: 'Tanzania',
      countryCode: 'TZ',
    },
    {
      name: 'Thailand',
      countryCode: 'TH',
    },
    {
      name: 'Togo',
      countryCode: 'TG',
    },
    {
      name: 'Tokelau',
      countryCode: 'TK',
    },
    {
      name: 'Tonga',
      countryCode: 'TO',
    },
    {
      name: 'Trinidad and Tobago',
      countryCode: 'TT',
    },
    {
      name: 'Tunisia',
      countryCode: 'TN',
    },
    {
      name: 'Turkey',
      countryCode: 'TR',
    },
    {
      name: 'Turkmenistan',
      countryCode: 'TM',
    },
    {
      name: 'Turks and Caicos Islands',
      countryCode: 'TC',
    },
    {
      name: 'Tuvalu',
      countryCode: 'TV',
    },
    {
      name: 'U.S. Minor Outlying Islands',
      countryCode: 'UM',
    },
    {
      name: 'U.S. Virgin Islands',
      countryCode: 'VI',
    },
    {
      name: 'Uganda',
      countryCode: 'UG',
    },
    {
      name: 'Ukraine',
      countryCode: 'UA',
    },
    {
      name: 'United Arab Emirates',
      countryCode: 'AE',
    },
    {
      name: 'United States',
      countryCode: 'US',
    },
    {
      name: 'Uruguay',
      countryCode: 'UY',
    },
    {
      name: 'Uzbekistan',
      countryCode: 'UZ',
    },
    {
      name: 'Vanuatu',
      countryCode: 'VU',
    },
    {
      name: 'Vatican City',
      countryCode: 'VA',
    },
    {
      name: 'Venezuela',
      countryCode: 'VE',
    },
    {
      name: 'Vietnam',
      countryCode: 'VN',
    },
    {
      name: 'Wallis and Futuna',
      countryCode: 'WF',
    },
    {
      name: 'Western Sahara',
      countryCode: 'EH',
    },
    {
      name: 'Yemen',
      countryCode: 'YE',
    },
    {
      name: 'Zambia',
      countryCode: 'ZM',
    },
    {
      name: 'Zimbabwe',
      countryCode: 'ZW',
    },
    {
      name: 'Ã…land',
      countryCode: 'AX',
    },
  ],
  fr: [
    {
      name: 'Afghanistan',
      countryCode: 'AF',
    },
    {
      name: 'Afrique du Sud',
      countryCode: 'ZA',
    },
    {
      name: 'Albanie',
      countryCode: 'AL',
    },
    {
      name: 'Algérie',
      countryCode: 'DZ',
    },
    {
      name: 'Allemagne',
      countryCode: 'DE',
    },
    {
      name: 'Andorre',
      countryCode: 'AD',
    },
    {
      name: 'Angola',
      countryCode: 'AO',
    },
    {
      name: 'Anguilla',
      countryCode: 'AI',
    },
    {
      name: 'Antarctique',
      countryCode: 'AQ',
    },
    {
      name: 'Antigua et Barbuda',
      countryCode: 'AG',
    },
    {
      name: 'Arabie saoudite',
      countryCode: 'SA',
    },
    {
      name: 'Argentine',
      countryCode: 'AR',
    },
    {
      name: 'Arménie',
      countryCode: 'AM',
    },
    {
      name: 'Aruba',
      countryCode: 'AW',
    },
    {
      name: 'Australie',
      countryCode: 'AU',
    },
    {
      name: 'Autriche',
      countryCode: 'AT',
    },
    {
      name: 'Azerbaïdjan',
      countryCode: 'AZ',
    },
    {
      name: 'Bahamas',
      countryCode: 'BS',
    },
    {
      name: 'Bahreïn',
      countryCode: 'BH',
    },
    {
      name: 'Bangladesh',
      countryCode: 'BD',
    },
    {
      name: 'Barbade',
      countryCode: 'BB',
    },
    {
      name: 'Belgique',
      countryCode: 'BE',
    },
    {
      name: 'Belize',
      countryCode: 'BZ',
    },
    {
      name: 'Bermudes',
      countryCode: 'BM',
    },
    {
      name: 'Bhoutan',
      countryCode: 'BT',
    },
    {
      name: 'Birmanie',
      countryCode: 'MM',
    },
    {
      name: 'Biélorussie',
      countryCode: 'BY',
    },
    {
      name: 'Bolivie',
      countryCode: 'BO',
    },
    {
      name: 'Bonaire, Saint-Eustache et Saba',
      countryCode: 'BQ',
    },
    {
      name: 'Bosnie-Herzégovine',
      countryCode: 'BA',
    },
    {
      name: 'Botswana',
      countryCode: 'BW',
    },
    {
      name: 'Brunéi Darussalam',
      countryCode: 'BN',
    },
    {
      name: 'Brésil',
      countryCode: 'BR',
    },
    {
      name: 'Bulgarie',
      countryCode: 'BG',
    },
    {
      name: 'Burkina Faso',
      countryCode: 'BF',
    },
    {
      name: 'Burundi',
      countryCode: 'BI',
    },
    {
      name: 'Bénin',
      countryCode: 'BJ',
    },
    {
      name: 'Cambodge',
      countryCode: 'KH',
    },
    {
      name: 'Cameroun',
      countryCode: 'CM',
    },
    {
      name: 'Canada',
      countryCode: 'CA',
    },
    {
      name: 'Cap-Vert',
      countryCode: 'CV',
    },
    {
      name: 'Centrafrique',
      countryCode: 'CF',
    },
    {
      name: 'Chili',
      countryCode: 'CL',
    },
    {
      name: 'Chine',
      countryCode: 'CN',
    },
    {
      name: 'Chypre',
      countryCode: 'CY',
    },
    {
      name: 'Colombie',
      countryCode: 'CO',
    },
    {
      name: 'Comores',
      countryCode: 'KM',
    },
    {
      name: 'Congo-Brazzaville',
      countryCode: 'CG',
    },
    {
      name: 'Congo-Kinshasa',
      countryCode: 'CD',
    },
    {
      name: 'Corée du Nord',
      countryCode: 'KP',
    },
    {
      name: 'Corée du Sud',
      countryCode: 'KR',
    },
    {
      name: 'Costa Rica',
      countryCode: 'CR',
    },
    {
      name: 'Croatie',
      countryCode: 'HR',
    },
    {
      name: 'Cuba',
      countryCode: 'CU',
    },
    {
      name: 'Curaçao',
      countryCode: 'CW',
    },
    {
      name: "Côte d'Ivoire",
      countryCode: 'CI',
    },
    {
      name: 'Danemark',
      countryCode: 'DK',
    },
    {
      name: 'Djibouti',
      countryCode: 'DJ',
    },
    {
      name: 'Dominique',
      countryCode: 'DM',
    },
    {
      name: 'Espagne',
      countryCode: 'ES',
    },
    {
      name: 'Estonie',
      countryCode: 'EE',
    },
    {
      name: 'Fidji',
      countryCode: 'FJ',
    },
    {
      name: 'Finlande',
      countryCode: 'FI',
    },
    {
      name: 'France',
      countryCode: 'FR',
    },
    {
      name: 'Gabon',
      countryCode: 'GA',
    },
    {
      name: 'Gambie',
      countryCode: 'GM',
    },
    {
      name: 'Ghana',
      countryCode: 'GH',
    },
    {
      name: 'Gibraltar',
      countryCode: 'GI',
    },
    {
      name: 'Grenade',
      countryCode: 'GD',
    },
    {
      name: 'Groenland',
      countryCode: 'GL',
    },
    {
      name: 'Grèce',
      countryCode: 'GR',
    },
    {
      name: 'Guadeloupe',
      countryCode: 'GP',
    },
    {
      name: 'Guam',
      countryCode: 'GU',
    },
    {
      name: 'Guatemala',
      countryCode: 'GT',
    },
    {
      name: 'Guernesey',
      countryCode: 'GG',
    },
    {
      name: 'Guinée',
      countryCode: 'GN',
    },
    {
      name: 'Guinée équatoriale',
      countryCode: 'GQ',
    },
    {
      name: 'Guinée-Bissau',
      countryCode: 'GW',
    },
    {
      name: 'Guyana',
      countryCode: 'GY',
    },
    {
      name: 'Guyane',
      countryCode: 'GF',
    },
    {
      name: 'Géorgie',
      countryCode: 'GE',
    },
    {
      name: 'Géorgie du Sud-et-les îles Sandwich du Sud',
      countryCode: 'GS',
    },
    {
      name: 'Haïti',
      countryCode: 'HT',
    },
    {
      name: 'Honduras',
      countryCode: 'HN',
    },
    {
      name: 'Hong Kong',
      countryCode: 'HK',
    },
    {
      name: 'Hongrie',
      countryCode: 'HU',
    },
    {
      name: 'Inde',
      countryCode: 'IN',
    },
    {
      name: 'Indonésie',
      countryCode: 'ID',
    },
    {
      name: 'Irak',
      countryCode: 'IQ',
    },
    {
      name: 'Iran',
      countryCode: 'IR',
    },
    {
      name: 'Irlande',
      countryCode: 'IE',
    },
    {
      name: 'Islande',
      countryCode: 'IS',
    },
    {
      name: 'Israël',
      countryCode: 'IL',
    },
    {
      name: 'Italie',
      countryCode: 'IT',
    },
    {
      name: 'Jamaïque',
      countryCode: 'JM',
    },
    {
      name: 'Japon',
      countryCode: 'JP',
    },
    {
      name: 'Jersey',
      countryCode: 'JE',
    },
    {
      name: 'Jordanie',
      countryCode: 'JO',
    },
    {
      name: 'Kazakhstan',
      countryCode: 'KZ',
    },
    {
      name: 'Kenya',
      countryCode: 'KE',
    },
    {
      name: 'Kirghizistan',
      countryCode: 'KG',
    },
    {
      name: 'Kiribati',
      countryCode: 'KI',
    },
    {
      name: 'Kosovo',
      countryCode: 'XK',
    },
    {
      name: 'Koweït',
      countryCode: 'KW',
    },
    {
      name: 'La Réunion',
      countryCode: 'RE',
    },
    {
      name: 'Laos',
      countryCode: 'LA',
    },
    {
      name: 'Lesotho',
      countryCode: 'LS',
    },
    {
      name: 'Lettonie',
      countryCode: 'LV',
    },
    {
      name: 'Liban',
      countryCode: 'LB',
    },
    {
      name: 'Liberia',
      countryCode: 'LR',
    },
    {
      name: 'Libye',
      countryCode: 'LY',
    },
    {
      name: 'Liechtenstein',
      countryCode: 'LI',
    },
    {
      name: 'Lituanie',
      countryCode: 'LT',
    },
    {
      name: 'Luxembourg',
      countryCode: 'LU',
    },
    {
      name: 'Macao',
      countryCode: 'MO',
    },
    {
      name: 'Macédoine du Nord',
      countryCode: 'MK',
    },
    {
      name: 'Madagascar',
      countryCode: 'MG',
    },
    {
      name: 'Malaisie',
      countryCode: 'MY',
    },
    {
      name: 'Malawi',
      countryCode: 'MW',
    },
    {
      name: 'Maldives',
      countryCode: 'MV',
    },
    {
      name: 'Mali',
      countryCode: 'ML',
    },
    {
      name: 'Malte',
      countryCode: 'MT',
    },
    {
      name: 'Maroc',
      countryCode: 'MA',
    },
    {
      name: 'Martinique',
      countryCode: 'MQ',
    },
    {
      name: 'Mauritanie',
      countryCode: 'MR',
    },
    {
      name: 'Mayotte',
      countryCode: 'YT',
    },
    {
      name: 'Mexique',
      countryCode: 'MX',
    },
    {
      name: 'Moldavie',
      countryCode: 'MD',
    },
    {
      name: 'Monaco',
      countryCode: 'MC',
    },
    {
      name: 'Mongolie',
      countryCode: 'MN',
    },
    {
      name: 'Montserrat',
      countryCode: 'MS',
    },
    {
      name: 'Monténégro',
      countryCode: 'ME',
    },
    {
      name: 'Mozambique',
      countryCode: 'MZ',
    },
    {
      name: 'Namibie',
      countryCode: 'NA',
    },
    {
      name: 'Nauru',
      countryCode: 'NR',
    },
    {
      name: 'Nicaragua',
      countryCode: 'NI',
    },
    {
      name: 'Niger',
      countryCode: 'NE',
    },
    {
      name: 'Nigeria',
      countryCode: 'NG',
    },
    {
      name: 'Nioué',
      countryCode: 'NU',
    },
    {
      name: 'Norvège',
      countryCode: 'NO',
    },
    {
      name: 'Nouvelle-Calédonie',
      countryCode: 'NC',
    },
    {
      name: 'Nouvelle-Zélande',
      countryCode: 'NZ',
    },
    {
      name: 'Népal',
      countryCode: 'NP',
    },
    {
      name: 'Oman',
      countryCode: 'OM',
    },
    {
      name: 'Ouganda',
      countryCode: 'UG',
    },
    {
      name: 'Ouzbékistan',
      countryCode: 'UZ',
    },
    {
      name: 'Pakistan',
      countryCode: 'PK',
    },
    {
      name: 'Palaos',
      countryCode: 'PW',
    },
    {
      name: 'Panama',
      countryCode: 'PA',
    },
    {
      name: 'Papouasie-Nouvelle Guinée',
      countryCode: 'PG',
    },
    {
      name: 'Paraguay',
      countryCode: 'PY',
    },
    {
      name: 'Pays-Bas',
      countryCode: 'NL',
    },
    {
      name: 'Philippines',
      countryCode: 'PH',
    },
    {
      name: 'Pologne',
      countryCode: 'PL',
    },
    {
      name: 'Polynésie Française',
      countryCode: 'PF',
    },
    {
      name: 'Porto Rico',
      countryCode: 'PR',
    },
    {
      name: 'Portugal',
      countryCode: 'PT',
    },
    {
      name: 'Pérou',
      countryCode: 'PE',
    },
    {
      name: 'Qatar',
      countryCode: 'QA',
    },
    {
      name: 'Roumanie',
      countryCode: 'RO',
    },
    {
      name: 'Royaume-Uni',
      countryCode: 'GB',
    },
    {
      name: 'Russie',
      countryCode: 'RU',
    },
    {
      name: 'Rwanda',
      countryCode: 'RW',
    },
    {
      name: 'République Dominicaine',
      countryCode: 'DO',
    },
    {
      name: 'République tchèque',
      countryCode: 'CZ',
    },
    {
      name: 'Sahara Occidental',
      countryCode: 'EH',
    },
    {
      name: 'Saint-Barthélemy',
      countryCode: 'BL',
    },
    {
      name: 'Saint-Christophe-et-Niévès',
      countryCode: 'KN',
    },
    {
      name: 'Saint-Marin',
      countryCode: 'SM',
    },
    {
      name: 'Saint-Martin',
      countryCode: 'MF',
    },
    {
      name: 'Saint-Martin',
      countryCode: 'SX',
    },
    {
      name: 'Saint-Pierre et Miquelon',
      countryCode: 'PM',
    },
    {
      name: 'Saint-Vincent-et-les-Grenadines',
      countryCode: 'VC',
    },
    {
      name: 'Sainte-Hélène',
      countryCode: 'SH',
    },
    {
      name: 'Sainte-Lucie',
      countryCode: 'LC',
    },
    {
      name: 'Salvador',
      countryCode: 'SV',
    },
    {
      name: 'Samoa',
      countryCode: 'WS',
    },
    {
      name: 'Samoa américaines',
      countryCode: 'AS',
    },
    {
      name: 'Serbie',
      countryCode: 'RS',
    },
    {
      name: 'Seychelles',
      countryCode: 'SC',
    },
    {
      name: 'Sierra Leone',
      countryCode: 'SL',
    },
    {
      name: 'Singapour',
      countryCode: 'SG',
    },
    {
      name: 'Slovaquie',
      countryCode: 'SK',
    },
    {
      name: 'Slovénie',
      countryCode: 'SI',
    },
    {
      name: 'Somalie',
      countryCode: 'SO',
    },
    {
      name: 'Soudan',
      countryCode: 'SD',
    },
    {
      name: 'Sri Lanka',
      countryCode: 'LK',
    },
    {
      name: 'Sud-Soudan',
      countryCode: 'SS',
    },
    {
      name: 'Suisse',
      countryCode: 'CH',
    },
    {
      name: 'Surinam',
      countryCode: 'SR',
    },
    {
      name: 'Suède',
      countryCode: 'SE',
    },
    {
      name: 'Svalbard et Jan Mayen',
      countryCode: 'SJ',
    },
    {
      name: 'Swaziland',
      countryCode: 'SZ',
    },
    {
      name: 'Syrie',
      countryCode: 'SY',
    },
    {
      name: 'São Tomé-et-Príncipe',
      countryCode: 'ST',
    },
    {
      name: 'Sénégal',
      countryCode: 'SN',
    },
    {
      name: 'Tadjikistan',
      countryCode: 'TJ',
    },
    {
      name: 'Tanzanie',
      countryCode: 'TZ',
    },
    {
      name: 'Taïwan',
      countryCode: 'TW',
    },
    {
      name: 'Tchad',
      countryCode: 'TD',
    },
    {
      name: 'Terres australes françaises',
      countryCode: 'TF',
    },
    {
      name: "Territoire britannique de l'océan Indien",
      countryCode: 'IO',
    },
    {
      name: 'Territoire palestinien',
      countryCode: 'PS',
    },
    {
      name: 'Thaïlande',
      countryCode: 'TH',
    },
    {
      name: 'Timor Oriental',
      countryCode: 'TL',
    },
    {
      name: 'Togo',
      countryCode: 'TG',
    },
    {
      name: 'Tokelau',
      countryCode: 'TK',
    },
    {
      name: 'Tonga',
      countryCode: 'TO',
    },
    {
      name: 'Trinidad et Tobago',
      countryCode: 'TT',
    },
    {
      name: 'Tunisie',
      countryCode: 'TN',
    },
    {
      name: 'Turkménistan',
      countryCode: 'TM',
    },
    {
      name: 'Turquie',
      countryCode: 'TR',
    },
    {
      name: 'Tuvalu',
      countryCode: 'TV',
    },
    {
      name: 'Ukraine',
      countryCode: 'UA',
    },
    {
      name: 'Uruguay',
      countryCode: 'UY',
    },
    {
      name: 'Vanuatu',
      countryCode: 'VU',
    },
    {
      name: 'Vatican',
      countryCode: 'VA',
    },
    {
      name: 'Vietnam',
      countryCode: 'VN',
    },
    {
      name: 'Vénézuéla',
      countryCode: 'VE',
    },
    {
      name: 'Wallis et Futuna',
      countryCode: 'WF',
    },
    {
      name: 'Yémen',
      countryCode: 'YE',
    },
    {
      name: 'Zambie',
      countryCode: 'ZM',
    },
    {
      name: 'Zimbabwe',
      countryCode: 'ZW',
    },
    {
      name: 'Égypte',
      countryCode: 'EG',
    },
    {
      name: 'Émirats Arabes Unis',
      countryCode: 'AE',
    },
    {
      name: 'Équateur',
      countryCode: 'EC',
    },
    {
      name: 'Érythrée',
      countryCode: 'ER',
    },
    {
      name: 'États fédérés de Micronésie',
      countryCode: 'FM',
    },
    {
      name: 'États-Unis',
      countryCode: 'US',
    },
    {
      name: 'Éthiopie',
      countryCode: 'ET',
    },
    {
      name: 'Île Bouvet',
      countryCode: 'BV',
    },
    {
      name: 'Île Christmas',
      countryCode: 'CX',
    },
    {
      name: 'Île Heard et îles McDonald',
      countryCode: 'HM',
    },
    {
      name: 'Île Maurice',
      countryCode: 'MU',
    },
    {
      name: 'Île Norfolk',
      countryCode: 'NF',
    },
    {
      name: 'Île de Man',
      countryCode: 'IM',
    },
    {
      name: 'Îles Caïmans',
      countryCode: 'KY',
    },
    {
      name: 'Îles Cocos',
      countryCode: 'CC',
    },
    {
      name: 'Îles Cook',
      countryCode: 'CK',
    },
    {
      name: 'Îles Féroé',
      countryCode: 'FO',
    },
    {
      name: 'Îles Malouines',
      countryCode: 'FK',
    },
    {
      name: 'Îles Mariannes du Nord',
      countryCode: 'MP',
    },
    {
      name: 'Îles Marshall',
      countryCode: 'MH',
    },
    {
      name: 'Îles Pitcairn',
      countryCode: 'PN',
    },
    {
      name: 'Îles Salomon',
      countryCode: 'SB',
    },
    {
      name: 'Îles Turques-et-Caïques',
      countryCode: 'TC',
    },
    {
      name: 'Îles Vierges',
      countryCode: 'VG',
    },
    {
      name: 'Îles Vierges des États-Unis',
      countryCode: 'VI',
    },
    {
      name: 'Îles mineures éloignées des États-Unis',
      countryCode: 'UM',
    },
    {
      name: 'Îles Åland',
      countryCode: 'AX',
    },
  ],
})
