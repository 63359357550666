import { presenceManagement, individualHistory } from '@/services/presencemanagement.service'

const SET_PRESENCE_MANAGEMENT = 'SET_PRESENCE_MANAGEMENT'
const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    presenceManagement: [],
    currentLocation: {},
  }
}

const state = getDefaultState()

const mutations = {
  [SET_PRESENCE_MANAGEMENT]: (state, presenceManagement) => {
    state.presenceManagement = presenceManagement
  },
  [SET_CURRENT_LOCATION]: (state, currentLocation) => {
    state.currentLocation = currentLocation
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getPresenceManagement({ commit, rootState }, { size = 1000, page = 1, search = '', filters = '' }) {
    const response = await presenceManagement(rootState.client.currentClient.id, size, page, search, filters)
    commit(SET_PRESENCE_MANAGEMENT, response.data.providers)
  },

  async getIndividualView({ commit, rootState }, externalId) {
    const response = await individualHistory(rootState.client.currentClient.id, externalId)
    commit(SET_CURRENT_LOCATION, response.data.providers)
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
