import axios from 'axios'
import store from '@/store'
import { postSessionRefreshApi } from './auth.service'
import { URLS } from '@/config/urls.config'
import { sleep } from '@/utils/utilities.util'

const api = axios.create({
  baseUlr: '/',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Authorization',
  },
})

const requestSuccess = reponse => {
  return reponse
}

const responseError = async error => {
  const originalRequest = error.config
  if (error.response.status === 498) {
    await postSessionRefreshApi()
    return api(originalRequest)
  } else if (
    error.response.status === 401 &&
    (error.response.data.message === 'AUTHENTICATION_FAILED' || error.response.data.message === 'INVALID_REFRESH_TOKEN')
  ) {
    await store.dispatch('backoffice/userLogout')
    window.location.assign(
      `${URLS.SSO}/?app_version=2&redirect=${encodeURIComponent(location.pathname + location.search)}&sign_out=1`
    )
    await sleep(2000)
  }
  return Promise.reject(error)
}

api.interceptors.response.use(requestSuccess, responseError)

export const sendGetRequest = async (url, config = null) => {
  const resp = await api.get(url, config)
  return resp.data
}

export const sendPutRequest = async (url, data, config = null) => {
  const resp = await api.put(url, data, config)
  return resp.data
}

export const sendPostRequest = async (url, data, config = null) => {
  const resp = await api.post(url, data, config)
  return resp.data
}

export const sendDeleteRequest = async (url, config = null) => {
  const resp = await api.delete(url, config)
  return resp.data
}
