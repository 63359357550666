import {
  geoJsons,
  getCategories,
  attributes,
  chains,
  accounts,
  getFullAccounts,
  locations,
  gmbAvailables,
  GUAdministratives,
  GUAttributs,
  postGUAdministrative,
  postGUAttribut,
  googleUpdatesLocations,
  getGmbBindLocations,
  updateGmbBindLocations,
  location as fetchLocation,
  locationMedias,
  locationUpdate,
  listInvitations,
  getPublicHolidaysApi,
  deleteLocationMedia,
  getServicesFromCategory,
  getLocalPosts,
  getLocalPostsDetail,
} from '@/services/gmb.service'

import { tableOptions } from '@/utils/table.util'
import AVAILABLES_COUNTRIES from '@/lovs/gmbAvailableCountries'
import LANGUAGE_CODES from '@/lovs/gmbLanguageCodes'
import displayErrorLogNoProduction from '../../utils/displayErrorLogNoProduction'
import Vue from 'vue'

const SET_WORLD_GEO_JSONS = 'SET_WORLD_GEO_JSONS'
const SET_CATEGORIES = 'SET_CATEGORIES'
const SET_GMB_AVAILABLES = 'SET_GMB_AVAILABLES'
const SET_GMB_BIND_LOCATIONS = 'SET_GMB_BIND_LOCATIONS'
const SET_SERVICES = 'SET_SERVICES'
const SET_ATTRIBUTES = 'SET_ATTRIBUTES'
const SET_ATTRIBUTES_PAGINATION = 'SET_ATTRIBUTES_PAGINATION'
const SET_CHAINS = 'SET_CHAINS'
const SET_CHAINS_PAGINATION = 'SET_CHAINS_PAGINATION'
const SET_ACCOUNTS = 'SET_ACCOUNTS'
const SET_ACCOUNTS_MOBILE = 'SET_ACCOUNTS_MOBILE'
const SET_ACCOUNTS_PAGINATION = 'SET_ACCOUNTS_PAGINATION'
const SET_ADMIN_ACCOUNTS = 'SET_ADMIN_ACCOUNTS'
const SET_ADMIN_ACCOUNTS_PAGINATION = 'SET_ADMIN_ACCOUNTS_PAGINATION'
const SET_PUBLIC_HOLIDAYS = 'SET_PUBLIC_HOLIDAYS'
const SET_LOCATIONS_GMB = 'SET_LOCATIONS_GMB'
const SET_LOCATIONS_GMB_PAGINATION = 'SET_LOCATIONS_GMB_PAGINATION'
const RESET_LOCATIONS_GMB = 'RESET_LOCATIONS_GMB'
const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
const SET_CURRENT_LOCATION_MEDIAS = 'SET_CURRENT_LOCATION_MEDIAS'
const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION'
const SET_INVITATIONS = 'SET_INVITATIONS'
const RESET_CURRENT_LOCATION_MEDIAS = 'RESET_CURRENT_LOCATION_MEDIAS'
const RESET_CURRENT_LOCATION = 'RESET_CURRENT_LOCATION'
const SET_CURRENT_LOCAL_POSTS = 'SET_CURRENT_LOCAL_POSTS'
const SET_CURRENT_LOCAL_POSTS_DETAIL = 'SET_CURRENT_LOCAL_POSTS_DETAIL'
const SET_GOOGLE_UPDATE_LOCATION = 'SET_GOOGLE_UPDATE_LOCATION'
const SET_GOOGLE_UPDATE_LOCATION_PAGINATION = 'SET_GOOGLE_UPDATE_LOCATION_PAGINATION'
const RESET_GOOGLE_UPDATE_LOCATION = 'RESET_GOOGLE_UPDATE_LOCATION'
const SET_GU_ADMINISTRATIVES = 'SET_GU_ADMINISTRATIVES'
const DELETE_GU_ADMINISTRATIVES = 'DELETE_GU_ADMINISTRATIVES'
const SET_GU_ATTRIBUTS = 'SET_GU_ATTRIBUTS'
const DELETE_GU_ATTRIBUTS = 'DELETE_GU_ATTRIBUTS'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    worldGeoJsons: [],
    availablesCountries: AVAILABLES_COUNTRIES,
    languageCodes: LANGUAGE_CODES,
    categories: { data: [] },
    attributes: [],
    attributesPagination: {},
    chains: [],
    chainsPagination: {},
    accounts: [],
    accountsMobile: [],
    accountsPagination: {},
    adminAccounts: [],
    adminAccountsPagination: {},
    locationsGmb: { data: [] },
    locationsGmbPagination: {},
    currentLocation: {},
    currentLocationMedias: {},
    publicHolidays: [],
    googleUpdatesLocations: [],
    googleUpdatesLocationsPagination: {},
    GUAdministratives: [],
    GUAttributs: [],
    invitations: {},
    services: [],
    gmbAvailables: [],
    gmbBindLocations: [],
    currentLocalPosts: { data: [] },
    currentLocalPostsDetail: null,
  }
}

const state = getDefaultState()

const mutations = {
  [SET_WORLD_GEO_JSONS]: (state, geoJsons) => {
    state.worldGeoJsons = geoJsons
  },
  [SET_CATEGORIES]: (state, categories) => {
    state.categories = categories
  },
  [SET_GMB_AVAILABLES]: (state, gmbAvailables) => {
    state.gmbAvailables = gmbAvailables
  },
  [SET_GMB_BIND_LOCATIONS]: (state, gmbBindLocations) => {
    state.gmbBindLocations = gmbBindLocations
  },
  [SET_SERVICES]: (state, services) => {
    state.services = services
  },
  [SET_ATTRIBUTES]: (state, attributes) => {
    state.attributes = attributes
  },
  [SET_ATTRIBUTES_PAGINATION]: (state, pagination) => {
    state.attributesPagination = pagination
  },
  [SET_CHAINS]: (state, chains) => {
    state.chains = chains
  },
  [SET_CHAINS_PAGINATION]: (state, pagination) => {
    state.chainsPagination = pagination
  },
  [SET_ACCOUNTS]: (state, accounts) => {
    state.accounts = accounts
  },
  [SET_ACCOUNTS_MOBILE]: (state, { accounts, reset }) => {
    if (reset) {
      state.accountsMobile = []
    }
    state.accountsMobile.push(...accounts)
  },
  [SET_ACCOUNTS_PAGINATION]: (state, pagination) => {
    state.accountsPagination = pagination
  },
  [SET_ADMIN_ACCOUNTS]: (state, accounts) => {
    state.adminAccounts = accounts
  },
  [SET_ADMIN_ACCOUNTS_PAGINATION]: (state, pagination) => {
    state.adminAccountsPagination = pagination
  },
  [SET_LOCATIONS_GMB]: (state, locations) => {
    state.locationsGmb = locations
  },
  [SET_LOCATIONS_GMB_PAGINATION]: (state, locationsGmbPagination) => {
    state.locationsGmbPagination = locationsGmbPagination
  },
  [SET_GOOGLE_UPDATE_LOCATION]: (state, googleUpdatesLocations) => {
    state.googleUpdatesLocations = googleUpdatesLocations
  },
  [SET_GOOGLE_UPDATE_LOCATION_PAGINATION]: (state, googleUpdatesLocationsPagination) => {
    state.googleUpdatesLocationsPagination = googleUpdatesLocationsPagination
  },
  [SET_GU_ADMINISTRATIVES]: (state, GUAdministratives) => {
    state.GUAdministratives = GUAdministratives
  },
  [DELETE_GU_ADMINISTRATIVES]: (state, index) => {
    state.GUAdministratives.splice(index, 1)
  },
  [SET_GU_ATTRIBUTS]: (state, GUAttributs) => {
    state.GUAttributs = GUAttributs
  },
  [DELETE_GU_ATTRIBUTS]: (state, payload) => {
    const GUAttributs = state.GUAttributs[payload.key]
    if (GUAttributs.length <= 1) {
      Vue.delete(state.GUAttributs, payload.key)
    } else {
      GUAttributs.splice(
        GUAttributs.findIndex(attr => attr.id === payload.id),
        1
      )
    }
  },
  [SET_CURRENT_LOCATION]: (state, location) => {
    state.currentLocation = location
  },
  [SET_CURRENT_LOCATION_MEDIAS]: (state, medias) => {
    state.currentLocationMedias = medias
  },
  [UPDATE_CURRENT_LOCATION]: (state, updatedLocation) => {
    state.currentLocation = Object.assign(state.currentLocation, updatedLocation)
  },
  [SET_PUBLIC_HOLIDAYS]: (state, publicHolidays) => {
    state.publicHolidays = publicHolidays
  },
  [SET_INVITATIONS]: (state, invitations) => {
    state.invitations = invitations
  },
  [SET_CURRENT_LOCAL_POSTS]: (state, localPosts) => {
    state.currentLocalPosts = localPosts
  },
  [SET_CURRENT_LOCAL_POSTS_DETAIL]: (state, localPostsDetail) => {
    state.currentLocalPostsDetail = localPostsDetail
  },
  [RESET_CURRENT_LOCATION_MEDIAS]: state => {
    state.currentLocationMedias = getDefaultState().currentLocationMedias
  },
  [RESET_CURRENT_LOCATION]: state => {
    state.currentLocation = getDefaultState().currentLocation
  },
  [RESET_LOCATIONS_GMB]: state => {
    state.locationsGmb = getDefaultState().locationsGmb
    state.locationsGmbPagination = getDefaultState().locationsGmbPagination
  },
  [RESET_GOOGLE_UPDATE_LOCATION]: state => {
    state.googleUpdatesLocations = getDefaultState().googleUpdatesLocations
    state.googleUpdatesLocationsPagination = getDefaultState().googleUpdatesLocationsPagination
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  loadLocationMedias: async ({ commit, rootState }, { locationName, currentAccountName }) => {
    commit(RESET_CURRENT_LOCATION_MEDIAS)
    const { data } = await locationMedias(rootState.client.currentClient.id, locationName, currentAccountName)
    commit(SET_CURRENT_LOCATION_MEDIAS, data)
    return Promise.resolve()
  },
  deleteLocationMedia: async ({ rootState }, { accountName, locationMediaId }) => {
    await deleteLocationMedia(rootState.client.currentClient.id, accountName, locationMediaId)
  },
  loadLocation: async ({ commit, rootState }, { locationName, currentAccountName, period }) => {
    commit(RESET_CURRENT_LOCATION)
    const { data } = await fetchLocation(rootState.client.currentClient.id, locationName, currentAccountName, period)
    commit(SET_CURRENT_LOCATION, data)
    return Promise.resolve()
  },
  updateLocation: async ({ commit, state, rootState }, { data, currentAccountName }) => {
    const response = await locationUpdate(
      rootState.client.currentClient.id,
      state.currentLocation.name,
      currentAccountName,
      data
    )
    commit(UPDATE_CURRENT_LOCATION, response.data)
  },
  loadServices: async ({ commit }, { regionCode, locale, categoryId }) => {
    const { data } = await getServicesFromCategory(regionCode, locale, categoryId)
    commit(SET_SERVICES, data.results)
  },
  loadCategories: async ({ commit }, { regionCode, view, locale, searchTerm = '' }) => {
    const params = {
      regionCode,
      locale,
      searchTerm,
      view,
    }
    const { data } = await getCategories(params)
    commit(SET_CATEGORIES, {
      data: data.results,
    })
  },
  getGUAdministratives: async ({ commit }, { id, clientId }) => {
    const { data } = await GUAdministratives(id, clientId)
    commit(SET_GU_ADMINISTRATIVES, data.results)
  },
  getGUAttributs: async ({ commit }, { id, clientId }) => {
    const { data } = await GUAttributs(id, clientId)
    commit(SET_GU_ATTRIBUTS, data.results)
  },
  postGUAdministrative: async ({ commit }, { id, clientId, status, index }) => {
    await postGUAdministrative(id, clientId, status)
    commit(DELETE_GU_ADMINISTRATIVES, index)
  },
  postGUAttribut: async ({ commit }, { id, clientId, status, key }) => {
    await postGUAttribut(id, clientId, status)
    commit(DELETE_GU_ATTRIBUTS, { key, id })
  },
  loadMoreCategories: async ({ commit, state }) => {
    const { data, next_page_token: nextPageToken } = await getCategories(state.categories.next)
    commit(SET_CATEGORIES, {
      data: [...state.categories.data, ...data.results],
      next: { ...state.categories.next, nextPageToken },
    })
  },
  loadLocations: async ({ commit, rootState }, { accountName, tableParams, resetLocations = true, search }) => {
    if (resetLocations) {
      commit(RESET_LOCATIONS_GMB)
    }
    const urlParams = tableOptions(tableParams, search, 'locationsGmb')
    const { data } = await locations(
      {
        clientId: rootState.client.currentClient.id,
        account: accountName,
      },
      urlParams === '&' ? '' : urlParams
    )
    commit(SET_LOCATIONS_GMB, { data: data.results })
    delete data.results
    commit(SET_LOCATIONS_GMB_PAGINATION, data)
  },
  loadGoogleUpdates: async ({ commit, rootState }, { accountName, tableParams, search, resetLocations = true }) => {
    if (resetLocations) {
      commit(RESET_GOOGLE_UPDATE_LOCATION)
    }
    const urlParams = tableOptions(tableParams, search, 'locationsGmb')
    const { data } = await googleUpdatesLocations(
      {
        clientId: rootState.client.currentClient.id,
        account: accountName,
      },
      urlParams === '&' ? '' : urlParams
    )
    commit(SET_GOOGLE_UPDATE_LOCATION, data.results)
    delete data.results
    commit(SET_GOOGLE_UPDATE_LOCATION_PAGINATION, data)
  },
  getGmbAvailables: async ({ commit }, { clientId }) => {
    const { data } = await gmbAvailables(clientId)
    commit(SET_GMB_AVAILABLES, data)
  },
  getGmbBindLocations: async ({ commit, rootState }, id) => {
    const { data } = await getGmbBindLocations(rootState.client.currentClient.id, id)
    commit(SET_GMB_BIND_LOCATIONS, data)
  },
  updateGmbBindLocations: async ({ commit, rootState }, locations) => {
    const { data } = await updateGmbBindLocations(
      rootState.location.currentLocation.clientId,
      rootState.location.currentLocation.id,
      locations
    )
    commit(SET_GMB_BIND_LOCATIONS, data)
  },
  loadMoreLocations: async ({ commit, state }) => {
    const { data, next_page_token: nextPageToken } = await locations(state.locationsGmb.next)
    commit(SET_LOCATIONS_GMB, {
      data: [...state.locationsGmb.data, ...data.results],
      next: { ...state.locationsGmb.next, nextPageToken },
    })
    commit(SET_LOCATIONS_GMB_PAGINATION, data)
  },
  getAttributes: ({ commit }, { tableParams, countryCode, languageCode, categoryId }) => {
    const urlParams = tableOptions(tableParams, '', 'attributes')
    return attributes(countryCode, languageCode, categoryId, urlParams)
      .then(response => {
        commit(SET_ATTRIBUTES, response.data.results)
        delete response.data.results
        commit(SET_ATTRIBUTES_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  getChains: ({ commit }, query) => {
    return chains(query)
      .then(response => {
        commit(SET_CHAINS, response.data.results)
        delete response.data.results
        commit(SET_CHAINS_PAGINATION, response.data)
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  clearStore: ({ commit }) => {
    commit(SET_CATEGORIES, {})
    commit(SET_ATTRIBUTES, {})
    commit(SET_CHAINS, [])
  },
  clearAttributes: ({ commit }) => {
    commit(SET_ATTRIBUTES, [])
  },
  getAccounts: ({ commit, rootState, state }, { tableParams, resetAccounts, search }) => {
    const urlParams = tableOptions(tableParams, search, 'accounts')
    return accounts(rootState.client.currentClient.id, urlParams)
      .then(response => {
        commit(SET_ACCOUNTS, response.data.results)
        commit(SET_ACCOUNTS_MOBILE, { accounts: response.data.results, reset: resetAccounts })
        delete response.data.results
        commit(SET_ACCOUNTS_PAGINATION, response.data)
        return Promise.resolve(state.accounts)
      })
      .catch(e => Promise.reject(e))
  },
  getFullAccounts: async ({ commit, state }, params) => {
    const urlParams = tableOptions(params, '', 'adminAccounts')
    const { data } = await getFullAccounts(urlParams)
    const { results, ...pagination } = data
    commit(SET_ADMIN_ACCOUNTS, results)
    commit(SET_ACCOUNTS_PAGINATION, pagination)
    return state.adminAccounts
  },
  getGeoJsons: async ({ commit }) => {
    const { features } = await geoJsons()
    commit(SET_WORLD_GEO_JSONS, features)
  },
  loadInvitations: async ({ commit }) => {
    const response = await listInvitations()
    commit(SET_INVITATIONS, response.data)
  },
  getPublicHolidays: async ({ commit }, { locale, from, length }) => {
    const { publicHolidays } = await getPublicHolidaysApi(locale, from, length)
    let holidays = []
    if (publicHolidays.length === 1) {
      holidays = [...publicHolidays[0].publicHolidays]
    } else if (publicHolidays.length === 2) {
      holidays = [...publicHolidays[0].publicHolidays, ...publicHolidays[1].publicHolidays]
    }
    commit(SET_PUBLIC_HOLIDAYS, holidays)
  },
  getLocalPosts: async ({ commit, rootState }, { account, location, page, size }) => {
    try {
      const params = { clientId: rootState.client.currentClient.id, account: account, location, page, size }
      const { data } = await getLocalPosts(params)
      const { results, ...rest } = data
      commit(SET_CURRENT_LOCAL_POSTS, {
        data: results,
        pagination: rest,
      })
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  getLocalPostsDetail: async ({ commit, rootState }, { account, location, id, page, size }) => {
    try {
      const { data } = await getLocalPostsDetail({
        clientId: rootState.client.currentClient.id,
        account,
        location,
        id,
        page,
        size,
      })
      commit(SET_CURRENT_LOCAL_POSTS_DETAIL, data)
    } catch (error) {
      displayErrorLogNoProduction(error)
    }
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

const getters = {
  hasMoreCategoriesToLoad: state => state.categories?.next?.nextPageToken?.length > 0,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
