import router from '@/router'
import { getClientApi, getClientFeatureListApi } from '@/services/client.service'

const SET_CLIENTS = 'SET_CLIENTS'
const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT'
const SET_CURRENT_CLIENT_FEATURE_LIST = 'SET_CURRENT_CLIENT_FEATURE_LIST'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    clients: [],
    currentClient: {},
    currentClientFeatureList: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_CLIENTS]: (state, clients) => {
    state.clients = clients
  },
  [SET_CURRENT_CLIENT]: (state, client) => {
    localStorage.setItem('client', JSON.stringify(client))
    state.currentClient = client
  },
  [SET_CURRENT_CLIENT_FEATURE_LIST]: (state, featureList) => {
    state.currentClientFeatureList = featureList
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  updateCurrentClient: async ({ dispatch }, currentClient) => {
    if (currentClient) {
      await Promise.all([
        dispatch('backoffice/resetAllStates', null, { root: true }),
        dispatch('getClient', currentClient.uuid),
      ])
    }
    dispatch('backoffice/closeEverything', { brands: true }, { root: true })
  },
  async getClient({ commit }, clientId) {
    const paramsClientId = router.currentRoute.params?.clientId
    const currentClient = await getClientApi(clientId)
    commit(SET_CURRENT_CLIENT, currentClient)

    if (paramsClientId !== currentClient.uuid) {
      router.push({
        params: { clientId: currentClient.uuid },
        query: router.currentRoute.query,
      })
    }
  },
  getClientFeaturesList: async ({ commit }, { clientId }) => {
    const { results } = await getClientFeatureListApi(clientId)

    commit(SET_CURRENT_CLIENT_FEATURE_LIST, results)
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
