import { sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest } from '@/services/api.service'
import { API_LPE, API_GMB, GTR_CORE } from '@/config/api.config'
import { formatedDate } from '@/utils/date.util'

export const locations = (clientId, urlParams) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATIONS}?clientId=${encodeURI(clientId)}${urlParams ? urlParams : ''}`
  )
}

export const getLocations = (clientId, query) => {
  return sendGetRequest(
    `${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_LOCATION}?client.id=${clientId}&query=${query}&usage[]=listing`
  )
}

export const searchLocations = (clientId, search, query = '') => {
  const searchString = Object.keys(search)
    .map(key => `${key}:${search[key]}`)
    .join(',')
  return sendGetRequest(
    `${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_LOCATION}?client.id=${clientId}&query=${query}&usage[]=listing&search=${searchString}&size=10000`
  )
}

export const getLocation = ({ clientId, locationId, frontOfficeId, localeId, isDefaultLocaleId }) => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.LPE_LOCATION}/${locationId}/extended`, {
    params: { clientId, frontOfficeId, localeId, default: isDefaultLocaleId },
  })
}

export const locationUpdate = ({ clientId, id, frontOfficeId, localeId, isDefaultLocaleId }, data) => {
  return sendPostRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCATION}`, data, {
    params: { clientId, id, frontOfficeId, localeId, default: isDefaultLocaleId },
  })
}

export const locationCreate = (clientId, data) => {
  return sendPutRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCATIONS}?clientId=${clientId}`, data)
}

export const locationInfoUpdate = (clientId, data) => {
  return sendPostRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCATIONS}?clientId=${clientId}`, data)
}

export const locationMapping = (clientId, accountGmb, account) => {
  return sendGetRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.LOCATION_MAPPING}?clientId=${clientId}&id=${accountGmb}&account=${account}`
  )
}

export const locationPostMapping = (clientId, accountGmb, account, placeId, data) => {
  return sendPostRequest(
    `${API_GMB.BASE_API_URL}${API_GMB.LOCATION_MAPPING}?clientId=${clientId}&id=${accountGmb}&account=${account}&place_id=${placeId}`,
    {
      ids: data,
    }
  )
}

export const locationOpeningHours = (clientId, locationId) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_OPENING_HOURS}?clientId=${clientId}&locationId=${locationId}`
  )
}

export const locationOpeningHourCreate = (clientId, locationId, openingHour) => {
  return sendPutRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_OPENING_HOURS}?clientId=${clientId}&locationId=${locationId}`,
    openingHour
  )
}

export const locationOpeningHourUpdate = (clientId, locationId, openingHourId, openingHour) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_OPENING_HOUR}?clientId=${clientId}&locationId=${locationId}&id=${openingHourId}`,
    openingHour
  )
}

export const locationOpeningHourDelete = (clientId, locationId, openingHourId) => {
  return sendDeleteRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_OPENING_HOUR}?clientId=${clientId}&locationId=${locationId}&id=${openingHourId}`
  )
}

export const locationUrlCreate = (clientId, locationId, url) => {
  return sendPutRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_URLS}?clientId=${clientId}&locationId=${locationId}`,
    { ...url, locationId } //remove when the fix on api will be applied
  )
}

export const locationUrlUpdate = (clientId, locationId, urlId, url) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_URL}?clientId=${clientId}&locationId=${locationId}&id=${urlId}`,
    url
  )
}

export const locationUrlDelete = (clientId, locationId, urlId) => {
  return sendDeleteRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_URL}?clientId=${clientId}&locationId=${locationId}&id=${urlId}`
  )
}

export const locationExceptionalOpeningHours = ({
  clientId,
  locationId,
  period = 'current',
  localeId,
  localeDefault,
}) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_EXCEPTIONAL_OPENING_HOURS}?clientId=${clientId}&locationId=${locationId}&period=${period}&localeId=${localeId}&default=${localeDefault}`
  )
}

export const locationExceptionalOpeningHourCreate = ({
  clientId,
  locationId,
  exceptionalOpeningHour,
  localeId,
  localeDefault,
}) => {
  return sendPutRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_EXCEPTIONAL_OPENING_HOURS}?clientId=${clientId}&locationId=${locationId}&localeId=${localeId}&default=${localeDefault}`,
    exceptionalOpeningHour
  )
}

export const locationExceptionalOpeningHourUpdate = ({
  clientId,
  locationId,
  exceptionalOpeningHour,
  localeId,
  localeDefault,
}) => {
  return sendPostRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_EXCEPTIONAL_OPENING_HOUR}?clientId=${clientId}&locationId=${locationId}&id=${exceptionalOpeningHour.id}&localeId=${localeId}&default=${localeDefault}`,
    exceptionalOpeningHour
  )
}

export const locationExceptionalOpeningHourDelete = ({
  clientId,
  locationId,
  exceptionalOpeningHour,
  localeId,
  localeDefault,
}) => {
  return sendDeleteRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATION_EXCEPTIONAL_OPENING_HOUR}?clientId=${clientId}&locationId=${locationId}&id=${exceptionalOpeningHour.id}&localeId=${localeId}&default=${localeDefault}`
  )
}

export const locationCreateGeoip = () => {
  return sendGetRequest(`${API_LPE.NETLIFY_GEO_IP}`)
}

export const locationExternalIdInfo = (clientId, externalId) => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.LOCATIONS}?clientId=${clientId}&search=externalId:${externalId}`
  )
}

export const getLocationCsv = (clientId, search = '') => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCATION_EXPORT}?clientId=${clientId}&search=${search}`, {
    responseType: 'blob',
  })
}

export const getEohCsv = clientId => {
  return sendGetRequest(
    `${API_LPE.BASE_API_URL}${API_LPE.EOH_EXPORT}?clientId=${clientId}&from=${formatedDate('now', 'YYYY-MM-DD')}`,
    {
      responseType: 'blob',
    }
  )
}

export const getUrlsCsv = clientId => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.URL_EXPORT}?clientId=${clientId}`, {
    responseType: 'blob',
  })
}

export const getClientGroups = clientId => {
  return sendGetRequest(`${API_LPE.BASE_API_URL}${API_LPE.LOCATION_GROUPS}?clientId=${clientId}&size=1000`)
}
