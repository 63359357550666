import { sendGetRequest } from '@/services/api.service'
import { API_PM } from '@/config/api.config'

export const presenceManagement = (clientId, size, page, search, filters) => {
  return sendGetRequest(`${API_PM.BASE_API_URL}${API_PM.PRESENCE_MANAGEMENT}`, {
    params: { clientId, size, page, search, filters },
  })
}

export const individualHistory = (clientId, externalId) => {
  return sendGetRequest(`${API_PM.BASE_API_URL}${API_PM.INDIVIDUAL_HISTORY}`, {
    params: { clientId, externalId },
  })
}
