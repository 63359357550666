import { sendGetRequest, sendPostRequest } from '@/services/api.service'
import { API_USER, API_AUTH, GTR_CORE } from '@/config/api.config'

export const forgetPassword = email => {
  return sendPostRequest(`${API_USER.BASE_API_URL}${API_USER.FORGET_PASSWORD}`, email)
}

export const getAuthApi = () => {
  return sendGetRequest(`${GTR_CORE.BASE_API_URL}${GTR_CORE.AUTH}`)
}

export const updateSettings = data => {
  return sendPostRequest(`${API_USER.BASE_API_URL}${API_USER.UPDATE_SETTINGS}`, data)
}

export const updatePwd = payload => {
  return sendPostRequest(`${API_AUTH.CHANGE_PASSWORD}`, payload)
}

export const gatewayCheck = data => {
  return sendPostRequest(`${API_USER.BASE_API_URL}${API_USER.GATEWAY_CHECK}`, data)
}

export const getModulesApi = () => {
  return sendGetRequest(`${API_USER.BASE_API_URL}${API_USER.MODULES}`)
}

export const getMenusApi = module => {
  return sendGetRequest(`${API_USER.BASE_API_URL}${API_USER.MENUS}?module=${module}`)
}

export const postSessionRefreshApi = () => {
  return sendPostRequest(
    `${API_AUTH.SESSION_REFRESH}`,
    {},
    {
      headers: {
        rid: 'session',
      },
    }
  )
}
