<template>
  <component
    :is="isLink ? 'a' : 'button'"
    :href="href ? href : null"
    class="ui-button"
    :class="buttonClasses"
    :type="type"
    :aria-label="iconOnly ? label : null"
    :aria-labelledby="!iconOnly ? label : null"
    :target="href ? '_blank' : null"
    :disabled="disabled"
    @click="onClick"
  >
    <span
      :class="{ 'ui-button__icon--small': iconSmall }"
      class="ui-button__icon ui-button__icon--default backoffice-icons"
      v-if="icon && !vuetifyIcon && !svg && !reverse"
    >
      {{ icon }}
    </span>
    <v-icon
      :class="{ 'ui-button__icon--small': iconSmall }"
      class="ui-button__icon ui-button__icon__vuetify ui-button__icon--default"
      v-if="icon && vuetifyIcon && !svg && !reverse"
    >
      {{ icon }}
    </v-icon>
    <img
      :src="require(`@/assets/img/svg/${svg}.svg`)"
      class="ui-button__svg ui-button__svg--default"
      :class="{
        'ui-button__svg--disabled': disabled,
      }"
      v-if="!icon && svg && !reverse"
    />
    <span class="ui-button__label" v-if="!iconOnly">
      {{ label }}
    </span>
    <span class="ui-button__icon ui-button__icon--reverse backoffice-icons" v-if="icon && !svg && reverse">
      {{ icon }}
    </span>
    <img
      :src="require(`@/assets/img/svg/${svg}.svg`)"
      class="ui-button__svg ui-button__svg--reverse"
      :class="{
        'ui-button__svg--disabled': disabled,
      }"
      v-if="!icon && svg && reverse"
    />
    <ui-loader v-if="loaderActive && disabled" class="ui-button--loader" stroke="#007bb5" :stroke-width="4"></ui-loader>
  </component>
</template>

<script>
import UiLoader from '@/components/UI/Loader.vue'

export default {
  name: 'Button',
  components: {
    UiLoader,
  },
  props: {
    vuetifyIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    button: {
      type: String,
      required: true,
    },
    iconSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    medium: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    iconOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: '',
    },
    svg: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    loaderActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      const classes = [`ui-button--${this.button}`]

      if (this.small) {
        classes.push(`ui-button--small`)
      }

      if (this.medium) {
        classes.push(`ui-button--medium`)
      }

      if (this.variant) {
        classes.push(`ui-button--${this.button}--${this.variant}`)
      }

      if (!this.iconOnly && !this.mobileToggle && (this.icon || this.svg)) {
        classes.push(`ui-button--text-icon`)
      } else if (this.mobileToggle && (this.icon || this.svg)) {
        classes.push(`ui-button--mobile-toggle`)
      } else if (this.iconOnly && (this.icon || this.svg)) {
        classes.push(`ui-button--icon`)
        if (this.iconSmall) {
          classes.push(`ui-button--icon--small`)
        }
      }

      return classes
    },
  },
  methods: {
    onClick($event) {
      /**
       * Emitted when the button is clicked.
       * @event click
       * @type {Event}
       */
      this.$emit('click', $event)
    },
  },
}
</script>

<style lang="scss">
.ui-button {
  &--cta {
    @include button-cta;
  }

  &--primary {
    @include button-primary;
  }

  &--secondary {
    @include button-secondary;
  }

  &--icon {
    @include button-icon;

    &--small {
      width: 32px;
      height: 32px;
    }
  }

  &--text-icon {
    @include button-text-icon;
  }

  &--mobile-toggle {
    @include button-mobile-toggle;
  }

  &--small {
    padding: 3px 9px;
    min-width: fit-content;
    min-height: fit-content;
  }

  &--medium {
    padding: 8px 12px;
    min-width: fit-content;
    min-height: fit-content;
  }

  &--loader {
    margin-left: 6px;
    width: 22px;
    height: 22px;
  }

  &__icon {
    &--small {
      font-size: 20px !important;
    }

    &__vuetify {
      color: inherit !important;
    }
  }
}
</style>
