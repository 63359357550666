import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import i18n from '@/i18n'
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)

/**
 * Returns the string of formated date, in the requested locale
 * @param {string} date date to parse
 * @param {string} locale locale to use for display
 * @returns {string} Parsed and formatted time
 */
export const formatedDate = (date, format, locale = i18n.locale) => {
  require(`dayjs/locale/${locale}.js`)
  dayjs.locale(locale)

  return date === 'now' ? dayjs().format(format) : dayjs(date).format(format)
}

export const orderOpeningHoursObject = openingHours => {
  return {
    ...(openingHours.monday && { monday: openingHours.monday }),
    ...(openingHours.tuesday && { tuesday: openingHours.tuesday }),
    ...(openingHours.wednesday && { wednesday: openingHours.wednesday }),
    ...(openingHours.thursday && { thursday: openingHours.thursday }),
    ...(openingHours.friday && { friday: openingHours.friday }),
    ...(openingHours.saturday && { saturday: openingHours.saturday }),
    ...(openingHours.sunday && { sunday: openingHours.sunday }),
  }
}

export const orderArrayOfWeekday = (days, dayKey) => {
  const sorter = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  }

  return days.sort(function sortByDay(day1, day2) {
    let day1Lowercase = day1[dayKey].toLowerCase()
    let day2Lowercase = day2[dayKey].toLowerCase()
    return sorter[day1Lowercase] - sorter[day2Lowercase]
  })
}
