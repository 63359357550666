import { list } from '@/services/kpis.service'

const getDefaultState = () => {
  return {
    list: [],
  }
}

const state = getDefaultState()

const SET_KPIS = 'SET_KPIS'
const RESET_STATE = 'RESET_STATE'

const mutations = {
  [SET_KPIS]: (state, list) => {
    state.list = list
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  getKpisList: async ({ commit, rootState }) => {
    const kpis = await list(rootState.client.currentClient.id)
    commit(SET_KPIS, kpis)
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
