const searchPropertyForView = cleanSearch => ({
  locations: `postalCode:${cleanSearch};name:${cleanSearch};externalId:${cleanSearch};city:${cleanSearch};company:${cleanSearch}`,
  clients: `name:${cleanSearch}`,
  frontOffices: `name:${cleanSearch}`,
  leads: `productId:${cleanSearch};productName:${cleanSearch};email:${cleanSearch}`,
  userExpress: `userExpressId:${cleanSearch}`,
  ohGroup: `ohGroupId:${cleanSearch}`,
  qa: `locationName:${cleanSearch}`,
  simpleSearch: `${cleanSearch}`,
  presenceManagement: `name:${cleanSearch};externalId:${cleanSearch}`,
  locationsGmb: `name:${cleanSearch}`,
})

function buildSearchField(search, view) {
  if (!(search && search.length > 0)) {
    return ''
  }
  const cleanSearch = encodeURIComponent(search.trim())

  return searchPropertyForView(cleanSearch)[view] || ''
}

function buildSort(sort, gtrCore) {
  if (!(sort && sort.field && sort.type && sort.type !== 'NONE')) return ''

  return gtrCore ? `&sort[${sort.field}]=${sort.type}` : `&sort=${sort.field},${sort.type}`
}

export const tableOptions = (tableParams, search, view, gtrCore = false) => {
  const options = tableParams || {}
  const urlParams = {
    size: options.size,
    page: options.page,
    search: [buildSearchField(search, view), options.filters].filter(v => v && v.length > 0).join(','),
    from: options.from,
    to: options.to,
    mode: options.mode,
    ue: options.ue,
    query: search,
  }

  const filteredQuery = Object.entries(urlParams)
    .filter(([, urlParamValue]) => urlParamValue)
    .map(([urlParamName, urlParamValue]) => `${urlParamName}=${urlParamValue}`)
    .join('&')

  return `&${filteredQuery}${buildSort(options.sort, gtrCore)}`
}

export const buildCoreApiParams = ({ params = {}, query = [], exists = [], options = {} }) => {
  const paramsBuild = Object.values(params)
    .map(param => {
      return `/${param}`
    })
    .join('')

  const existsBuild = exists
    .map((e, index) => {
      return `${index > 0 ? ',exists' : 'exists'}${e.key}=${e.value}`
    })
    .join('')

  const queryBuild = query
    .filter(q => q.value)
    .map((q, index) => {
      return `${index === 0 && !existsBuild ? '' : '&'}${q.key}=${q.value}`
    })
    .join('')

  const optionsBuild = Object.entries(options)
    .map((option, index) => {
      return `${index === 0 && !queryBuild ? '' : '&'}${option[0]}=${option[1]}`
    })
    .join('')

  return paramsBuild + '?' + existsBuild + queryBuild + optionsBuild
}
