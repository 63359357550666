import {
  uploadMedia,
  cloneMedia,
  removeMedia,
  geocode,
  getInternationalPhone,
  subDivisions,
} from '@/services/tool.service'
import { formatedDate } from '@/utils/date.util'

const SET_SUB_DIVISIONS = 'SET_SUB_DIVISIONS'
const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    subDivisions: null,
  }
}

const state = getDefaultState()

const mutations = {
  [SET_SUB_DIVISIONS]: (state, subDivisions) => {
    state.subDivisions = subDivisions
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  uploadToolMedia: ({ rootState }, params) => {
    if (params.image) {
      let media = {}
      switch (params.type) {
        case 'logo':
          media = {
            target: 'logo',
            name: `${params.name}-logo-${rootState.frontoffice.currentFrontOffice.localeCode}-${formatedDate(
              'now',
              'x'
            )}`,
          }
          break
        case 'favicon':
          media = {
            target: 'logo',
            name: `${params.name}-favicon-${rootState.frontoffice.currentFrontOffice.localeCode}-${formatedDate(
              'now',
              'x'
            )}`,
          }
          break
        case 'event':
          media = {
            target: 'events',
            name: `${params.name}-event-${params.title}-${
              rootState.frontoffice.currentFrontOffice.localeCode
            }-${formatedDate('now', 'x')}`,
          }
          break
        case 'dynamicContent':
          media = {
            target: 'eventGroup',
            name: `${params.name}-event-${params.title}-${
              rootState.frontoffice.currentFrontOffice.localeCode
            }-${formatedDate('now', 'x')}`,
          }
          break
        case 'hotPictures':
          media = {
            target: 'push-products',
            name: `${params.name}-product-${params.title}-${
              rootState.frontoffice.currentFrontOffice.localeCode
            }-${formatedDate('now', 'x')}`,
          }
          break
        case 'locationPictures':
          media = {
            target: 'location',
            name: `${params.name}-location-${rootState.frontoffice.currentFrontOffice.localeCode}-${formatedDate(
              'now',
              'x'
            )}`,
          }
          break
        case 'team':
          media = {
            target: 'team',
            name: `${params.name}-team-${params.title}-${
              rootState.frontoffice.currentFrontOffice.localeCode
            }-${formatedDate('now', 'x')}`,
          }
          break
      }
      if (params.element) {
        media.element = params.element
      }
      media.file = params.image
      return uploadMedia(
        rootState.client.currentClient.id,
        media,
        params.frontOfficeId ? params.frontOfficeId : null
      ).then(response => {
        return Promise.resolve(response.data.url)
      })
    }
    return Promise.resolve(params.picture)
  },
  cloneToolMedia: ({ rootState }, params) => {
    if (params.picture) {
      let media = {}
      switch (params.type) {
        case 'event':
          media = {
            target: 'events',
            name: `${params.name}-event-${params.title}-${
              rootState.frontoffice.currentFrontOffice.localeCode
            }-${formatedDate('now', 'x')}`,
          }
          break
        case 'hotPictures':
          media = {
            target: 'push-products',
            name: `${params.name}-product-${params.title}-${
              rootState.frontoffice.currentFrontOffice.localeCode
            }-${formatedDate('now', 'x')}`,
          }
          break
        case 'locationPictures':
          media = {
            target: 'location',
            name: `${params.name}-location-${rootState.frontoffice.currentFrontOffice.localeCode}-${formatedDate(
              'now',
              'x'
            )}`,
          }
          break
      }
      if (params.element) {
        media.element = params.element
      }
      media.url = params.picture
      return cloneMedia(rootState.client.currentClient.id, media).then(response => {
        return Promise.resolve(response.data.url)
      })
    }
    return Promise.resolve()
  },
  removeToolMedia: ({ rootState }, params) => {
    if (params.picture) {
      return removeMedia(rootState.client.currentClient.id, {
        url: params.picture,
      }).then(() => {
        return Promise.resolve()
      })
    }
    return Promise.resolve()
  },
  geocodeAddress: (_, address) => {
    return geocode(address)
      .then(response => Promise.resolve(response.data))
      .catch(() => Promise.reject())
  },
  getInternationalPhone: (_, data) => {
    return getInternationalPhone(data)
      .then(response => Promise.resolve(response.data[0]))
      .catch(() => Promise.reject())
  },
  getSubDivisions: ({ commit }, { locale, countryCode = '' }) => {
    return subDivisions(locale, countryCode)
      .then(response => {
        commit(SET_SUB_DIVISIONS, response)
      })
      .catch(() => Promise.reject())
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
