export const compareString = (source, reference) => {
  const markedStr = []
  let markedCount = 0
  let markIsInsert = 0
  for (let index = 0; index < source.length; index++) {
    if (reference.length < index || source[index] !== reference[index]) {
      markedStr.push(`${markIsInsert === 1 ? '' : '<mark>'}${source[index]}`)
      markIsInsert = 1
    } else if (markIsInsert === 1) {
      markedCount += 1
      markedStr.push(`</mark>${source[index]}`)
      markIsInsert = 0
    } else {
      markedStr.push(`${source[index]}`)
    }
  }
  // File seems differ, mark the entire file
  if (markedCount > 10) {
    return `<mark>${source}</mark>`
  }
  return markedStr.join('')
}

export const getShortLocal = locale => locale.substring(0, 2)

// Clean accent(diacritic) on string
export function removeDiacritics(text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function isMatch(searchPattern, targetValue) {
  const regExp = new RegExp(searchPattern, 'gi')
  return regExp.test(targetValue)
}
